<template>
  <div class="container">
    <h1 class="is-size-3 mb-3">
      Forecast
    </h1>

    <b-button
      icon-left="plus"
      type="is-primary"
      size="is-small"
      class="mr-4 mb-4 is-pulled-right"
      rounded
      outlined
      @click="addItem"
    >
      Add
    </b-button>

    <b-field v-if="Object.keys(productFamilies).length > 0">
      <b-button
        size="is-small"
        class="mr-2"
        @click="toggleProductFamilies"
      >
        All/none
      </b-button>
      <b-checkbox
        v-model="productFamilyIds"
        v-for="(family, id) in productFamilies"
        :key="'family' + id"
        :native-value="id"
        size="is-small"
        class="mr-2"
      >
        {{ family.name }}
      </b-checkbox>
      <b-checkbox
        v-model="productFamilyIds"
        native-value="other"
        size="is-small"
        class="mr-2"
      >
        Other
      </b-checkbox>
    </b-field>

    <table class="table is-striped is-fullwidth">
      <thead>
        <tr>
          <th>Debit</th>
          <th>Credit</th>
          <th colspan="99">Label</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="accountsBalance && accountsBalance.length > 0">
          <td colspan="2" />
          <th
            class="pt-5 pb-5"
            colspan="99"
          >
            <b-tag
              class="mr-2"
              type="is-info"
              rounded
            >
              {{ dayLabel(accountsBalance[0].date) }}
            </b-tag>
            {{ accountsBalance[0].balance | $(0) }}
          </th>
        </tr>
        <template v-for="month in byMonth">
          <tr
            v-for="item in month.items"
            :key="item.id"
            :class="{ greyed: !item.active }"
            class="clickable"
            @click="editItem(item.id)"
          >
            <td>
              <template v-if="item.amount < 0">
                {{ -item.amount | $(0) }}
              </template>
            </td>
            <td class="has-text-success">
              <template v-if="item.amount > 0">
                {{ item.amount | $(0) }}
              </template>
            </td>
            <td>
              {{ item.description }}
              <b-tag v-if="item.family">
                {{ item.family.name }}
              </b-tag>
            </td>
            <td>
              <div class="buttons">
                <b-button
                  size="is-small"
                  :icon-left="item.active ? 'eye' : 'eye-slash'"
                  outlined
                  @click.stop="activeItem(item)"
                />
                <b-button
                  size="is-small"
                  icon-left="trash"
                  type="is-danger"
                  outlined
                  @click.stop="removeItem(item.id)"
                />
              </div>
            </td>
          </tr>
          <tr :key="month.label">
            <td class="pt-5 greyed">
              {{ month.debit | $(0) }}
            </td>
            <td class="pt-5 greyed">
              {{ month.credit | $(0) }}
            </td>
            <th
              class="pt-5 pb-5"
              colspan="99"
            >
              <div class="is-size-7 is-pulled-right mr-5">
                {{ month.cumsum | $(0) }}
              </div>
              <b-tag
                class="mr-2"
                type="is-info"
                rounded
              >
                {{ month.label }}
              </b-tag>
              {{ month.balance | $(0) }}
              <b-tag
                class="ml-2"
                rounded
              >
                {{ month.sum | $(0) }}
              </b-tag>
            </th>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import moment from 'moment'

import ViewMixin from './_mixin'

import ModalForm from '@/components/Modal/Forecast.vue'

export default {
  mixins: [ViewMixin({
    query: `forecasts {
      id month description amount active productFamilyId
    }`,
    modalComponent: ModalForm,
    deleteMutationName: 'forecastDelete',
  })],
  apollo: {
    accountsBalance: gql`query {
      accountsBalance {
        date balance
      }
    }`,
  },
  data () {
    return {
      productFamilyIds: [],
    }
  },
  computed: {
    productFamilies () {
      if (!this.items) return {}
      const productFamilies = {}
      this.items.forEach((item) => {
        if (!item.productFamilyId) return
        if (productFamilies[item.productFamilyId] !== undefined) return
        const family = this.$store.getters.productFamiliesById[item.productFamilyId]
        if (!family) return
        productFamilies[item.productFamilyId] = family
      })
      return productFamilies
    },
    byMonth () {
      if (!this.items) return []
      const byMonth = {}
      this.items.forEach((item) => {
        if (item.productFamilyId && !this.productFamilyIds.includes(item.productFamilyId)) return
        if (!item.productFamilyId && !this.productFamilyIds.includes('other')) return
        if (byMonth[item.month] === undefined) byMonth[item.month] = []
        byMonth[item.month].push({
          ...item,
          family: item.productFamilyId ? this.productFamilies[item.productFamilyId] : null,
        })
      })
      let balance = this.accountsBalance && this.accountsBalance.length > 0
        ? this.accountsBalance[0].balance
        : 0
      let cumsum = 0
      return Object.keys(byMonth).map((month) => {
        const sum = byMonth[month].reduce((sum, item) => item.active ? sum + item.amount : sum, 0)
        balance += sum
        cumsum += sum
        return {
          label: moment(month).endOf('month').format('D MMM YY'),
          debit: byMonth[month].reduce((sum, item) => item.active && item.amount < 0 ? sum - item.amount : sum, 0),
          credit: byMonth[month].reduce((sum, item) => item.active && item.amount > 0 ? sum + item.amount : sum, 0),
          sum,
          cumsum,
          balance,
          items: byMonth[month],
        }
      })
    },
  },
  watch: {
    items (_v, oldV) {
      if (oldV === undefined) {
        this.items.forEach((item) => {
          if (!item.productFamilyId) return
          if (!this.productFamilyIds.includes(item.productFamilyId))
            this.productFamilyIds.push(item.productFamilyId)
        })
        this.productFamilyIds.push('other')
      }
    },
  },
  mounted () {
    this.$store.dispatch('getProductFamilies')
  },
  methods: {
    dayLabel (day) {
      return moment(day).format('D MMM YY')
    },
    toggleProductFamilies () {
      if (this.productFamilyIds.length < Object.keys(this.productFamilies).length + 1)
        this.productFamilyIds = [
          ...Object.keys(this.productFamilies),
          'other'
        ]
      else
        this.productFamilyIds = []
    },
    async activeItem (item) {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation ($item: ForecastInput!) {
            forecastCreateOrUpdate(item: $item) { id }
          }`,
          variables: {
            item: {
              id: item.id,
              active: !item.active,
            },
          },
        })
        this.$apollo.queries.items.refetch()
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }

    },
  },
}
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}
.greyed {
  opacity: .3;
}
</style>
