import Vue from 'vue'
import VueApollo from 'vue-apollo'
import store from 'store'

import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'

// use Vue plugin
Vue.use(VueApollo)

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: '/graphql',
})

// auth using JWT
const authLink = setContext((_, { headers }) => {
  const token = store.get('erpToken', null)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

export default new VueApollo({
  defaultClient: apolloClient,
})
