<template>
  <div v-if="result">
    <table class="table">
      <thead>
        <th>Name</th>
        <th>Revenue</th>
        <th>Costs</th>
      </thead>
      <tbody>
        <template v-for="(family, familyId) in result.productFamilies">
          <tr :key="'family' + familyId">
            <td>
              {{ family.name }}
            </td>
            <td
              class="clickable"
              @click="popDetails(family.incomes)"
            >
              {{ family.sumIncomes | $(0) }}
            </td>
            <td
              class="clickable"
              @click="popDetails(family.costs)"
            >
              {{ family.sumCosts | $(0) }}
            </td>
          </tr>
          <!-- <template v-for="id in family.productIds">
            <tr
              v-if="result.products[id]"
              :key="'product' + id"
            >
              <td class="pl-4 is-size-7">
                {{ result.products[id].name }}
              </td>
              <td
                class="clickable is-size-7"
                @click="popDetails(result.products[id].incomes)"
              >
                {{ result.products[id].sumIncomes | $(0) }}
              </td>
              <td
                class="clickable is-size-7"
                @click="popDetails(result.products[id].costs)"
              >
                {{ result.products[id].sumCosts | $(0) }}
              </td>
            </tr>
          </template> -->
        </template>
        <tr>
          <th>Subtotal</th>
          <th>{{ result.totalProducts.incomes | $(0) }}</th>
          <th>{{ result.totalProducts.costs | $(0) }}</th>
        </tr>
        <template v-for="(category, name) in result.categories">
          <tr :key="'category' + name">
            <td>
              {{ name }}
            </td>
            <td
              class="clickable"
              @click="popDetails(category.incomes)"
            >
              {{ category.sumIncomes | $(0) }}
            </td>
            <td
              class="clickable"
              @click="popDetails(category.costs)"
            >
              {{ category.sumCosts | $(0) }}
            </td>
          </tr>
        </template>
        <tr>
          <td>
            <em>Uncategorized</em>
          </td>
          <td
            class="clickable"
            @click="popDetails(result.unknown.incomes)"
          >
            {{ result.unknown.sumIncomes | $(0) }}
          </td>
          <td
            class="clickable"
            @click="popDetails(result.unknown.costs)"
          >
            {{ result.unknown.sumCosts | $(0) }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th>
            Total
          </th>
          <th>
            {{ result.total.incomes | $(0) }}
          </th>
          <th>
            {{ result.total.costs | $(0) }}
          </th>
        </tr>
      </tfoot>
    </table>

    <b-modal v-model="showDetailsModal">
      <b-table
        :data="details"
        striped
      >
        <b-table-column
          v-slot="props"
          label="Date"
          field="date"
          width="120"
          sortable
        >
          {{ props.row.date }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Amount"
          field="amount"
          width="100"
          numeric
          sortable
        >
          {{ props.row.amountUsd | $ }}
        </b-table-column>
        <b-table-column
          v-slot="props"
        >
          {{ detailsLabel(props.row) }}
        </b-table-column>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      showDetailsModal: false,
      details: [],
    }
  },
  computed: {
    result () {
      if (!this.$store.state.products.length) return null
      if (!this.$store.state.productFamilies.length) return null

      const result = {
        productFamilies: {},
        products: {},
        categories: {},
        unknown: {
          incomes: [],
          costs: [],
          sumIncomes: 0,
          sumCosts: 0,
        },
        total: {
          incomes: 0,
          costs: 0,
        },
        totalProducts: {
          incomes: 0,
          costs: 0,
        },
      }
      this.data.costs.forEach((item) => {
        result.total.costs += item.amountUsd
        if (item.category) {
          if (result.categories[item.category] === undefined)
            result.categories[item.category] = {
              incomes: [],
              costs: [],
              sumIncomes: 0,
              sumCosts: 0,
            }
          result.categories[item.category].costs.push(item)
          return
        }
        if (!item.productFamilyId) {
          result.unknown.costs.push(item)
          return
        }
        result.totalProducts.costs += item.amountUsd
        if (item.productId) {
          if (!result.products[item.productId]) {
            const product = this.$store.getters.productsById[item.productId]
            result.products[item.productId] = {
              name: product?.name || '?',
              incomes: [],
              costs: [],
              sumIncomes: 0,
              sumCosts: 0,
            }
          }
          result.products[item.productId].costs.push(item)
        }
        if (item.productFamilyId) {
          if (!result.productFamilies[item.productFamilyId]) {
            const productFamily = this.$store.getters.productFamiliesById[item.productFamilyId]
            result.productFamilies[item.productFamilyId] = {
              name: productFamily?.name || '?',
              productIds: this.$store.state.products.filter(i => i.family?.id === item.productFamilyId).map(i => i.id),
              incomes: [],
              costs: [],
              sumIncomes: 0,
              sumCosts: 0,
            }
          }
          result.productFamilies[item.productFamilyId].costs.push(item)
        }
      })
      this.data.incomes.forEach((item) => {
        result.total.incomes += item.amountUsd
        if (item.category) {
          if (result.categories[item.category] === undefined)
            result.categories[item.category] = {
              incomes: [],
              costs: [],
              sumIncomes: 0,
              sumCosts: 0,
            }
          result.categories[item.category].incomes.push(item)
          return
        }
        if (!item.productFamilyId) {
          result.unknown.incomes.push(item)
          return
        }
        result.totalProducts.incomes += item.amountUsd
        if (item.productId) {
          if (!result.products[item.productId]) {
            const product = this.$store.getters.productsById[item.productId]
            result.products[item.productId] = {
              name: product?.name || '?',
              incomes: [],
              costs: [],
              sumIncomes: 0,
              sumCosts: 0,
            }
          }
          result.products[item.productId].incomes.push(item)
        }
        if (item.productFamilyId) {
          if (!result.productFamilies[item.productFamilyId]) {
            const productFamily = this.$store.getters.productFamiliesById[item.productFamilyId]
            result.productFamilies[item.productFamilyId] = {
              name: productFamily?.name || '?',
              productIds: this.$store.state.products.filter(i => i.family?.id === item.productFamilyId).map(i => i.id),
              incomes: [],
              costs: [],
              sumIncomes: 0,
              sumCosts: 0,
            }
          }
          result.productFamilies[item.productFamilyId].incomes.push(item)
        }
      })
      // sums
      result.unknown.sumCosts = result.unknown.costs.reduce((sum, x) => sum + x.amountUsd, 0)
      result.unknown.sumIncomes = result.unknown.incomes.reduce((sum, x) => sum + x.amountUsd, 0)
      for (const i in result.products) {
        result.products[i].sumCosts = result.products[i].costs.reduce((sum, x) => sum + x.amountUsd, 0)
        result.products[i].sumIncomes = result.products[i].incomes.reduce((sum, x) => sum + x.amountUsd, 0)
      }
      for (const i in result.productFamilies) {
        result.productFamilies[i].sumCosts = result.productFamilies[i].costs.reduce((sum, x) => sum + x.amountUsd, 0)
        result.productFamilies[i].sumIncomes = result.productFamilies[i].incomes.reduce((sum, x) => sum + x.amountUsd, 0)
      }
      for (const i in result.categories) {
        result.categories[i].sumCosts = result.categories[i].costs.reduce((sum, x) => sum + x.amountUsd, 0)
        result.categories[i].sumIncomes = result.categories[i].incomes.reduce((sum, x) => sum + x.amountUsd, 0)
      }
      return result
    },
  },
  methods: {
    popDetails (items) {
      if (items.length === 0) {
        this.$buefy.toast.open({
          message: 'Nothing to see here!',
        })
        return
      }
      this.showDetailsModal = true
      this.details = items
    },
    detailsLabel (item) {
      if (item.description) return item.description
      const tx = this.data.accountTxes.find(i => i.id === item.accountTxId)
      return tx ? tx.details : '?'
    },
  },
}
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}
</style>
