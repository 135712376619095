import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import apollo from './apollo'
import store from './store'
import router from './router'
import './filters'

Vue.use(Buefy, {
  defaultIconPack: 'fas',
})

Vue.config.productionTip = false

new Vue({
  apolloProvider: apollo,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
