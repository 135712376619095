<template>
  <div class="card p-4">
    <h2 class="is-size-3 mb-4">Invoice {{ id }}</h2>
    <div
      v-if="item"
      class="columns"
    >
      <div class="column is-narrow">
        <h2 class="has-text-weight-bold is-size-7">Shipping</h2>
        <pre>{{ item.shippingAddress }}</pre>
      </div>
      <div class="column is-narrow">
        <h2 class="has-text-weight-bold is-size-7">Billing</h2>
        <pre>{{ item.billingAddress }}</pre>
      </div>
      <div class="column is-size-4 has-text-weight-bold has-text-right pt-5">
        Total: {{ item.amount | $ }}
      </div>
    </div>
    <table
      v-if="items"
      class="table is-fullwidth"
    >
      <thead>
        <th>Item</th>
        <th>Qty</th>
        <th>Unit price</th>
        <th>Price</th>
      </thead>
      <tbody>
        <tr
          v-for="(i, index) in items"
          :key="index"
        >
          <td>
            {{ i.name }}
            <div
              v-if="i.description"
              class="is-size-7"
            >
              {{ i.description }}
            </div>
          </td>
          <td>{{ i.qty }}</td>
          <td>{{ i.unitPrice | $ }}</td>
          <td>{{ i.qty * i.unitPrice | $ }}</td>
        </tr>
      </tbody>
    </table>
    <b-checkbox
      v-if="item && item.isSent"
      v-model="sendAgain"
      class="mb-3"
    >
      This invoice has already been sent. Send again?
    </b-checkbox>
    <div class="buttons">
      <b-button
        :disabled="!canSend"
        type="is-primary"
        icon-left="paper-plane"
        @click="sendInvoice"
      >
        Send
      </b-button>
      <b-button
        icon-left="download"
        @click="downloadInvoice"
      >
        PDF
      </b-button>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      item: null,
      sendAgain: false,
    }
  },
  computed: {
    items () {
      return JSON.parse(this.item?.items)
    },
    canSend () {
      if (!this.item) return false
      if (!this.item.isSent) return true
      return this.sendAgain
    },
  },
  async created () {
    try {
      const { data } = await this.$apollo.query({
        query: gql`query ($id: ID!) {
          invoice (id: $id) {
            id date
            shippingAddress billingAddress customerEmail isSent
            items amount
            base64
          }
        }`,
        variables: {
          id: this.id,
        },
        fetchPolicy: 'no-cache',
      })
      this.item = data.invoice
    } catch (e) {
      this.$buefy.toast.open({
        message: e.message,
        type: 'is-danger',
      })
    }
  },
  methods: {
    async sendInvoice () {
      try {
        const { data } = await this.$apollo.query({
          query: gql`query ($id: ID!) {
            invoiceSend (id: $id)
          }`,
          variables: {
            id: this.id,
          },
          fetchPolicy: 'no-cache',
        })
        if (data.invoiceSend) {
          this.$buefy.toast.open('Invoice sent!')
          this.$parent.close()
        } else
          this.$buefy.toast.open({
            message: 'Invoice could not be sent',
            type: 'is-danger',
          })
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
    },
    async downloadInvoice () {
      const fileUrl = `data:application/pdf;base64,${this.item.base64}`
      const data = await fetch(fileUrl)
      const blob = await data.blob()
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.setAttribute('download', `FK-invoice-${this.item.id}.pdf`)
      link.click()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
