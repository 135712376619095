<template>
  <div>
    <span
      v-if="item"
      :class="{ 'is-size-7': size === 'is-small' }"
      class="item-value"
    >
      {{ displayLabel(item) }}
      <span
        style="cursor: pointer"
        @click="$emit('input', null)"
      >
        <b-icon icon="times-circle" />
      </span>
    </span>
    <b-autocomplete
      v-else
      ref="autofocus"
      v-model="name"
      :custom-formatter="displayLabel"
      :data="options"
      :size="size"
      placeholder="Search order by customer name or invoice number"
      clearable
      open-on-focus
      keep-first
      @select="setValue"
    >
      <template #empty>
        No results found
      </template>
    </b-autocomplete>
  </div>
</template>

<script>
import AutocompleteMixin from './_mixin'

export default {
  mixins: [AutocompleteMixin],
  computed: {
    item () {
      if (!this.value) return null
      return this.$store.state.orders.find(i => i.id === this.value)
    },
    orders () {
      return this.$store.state.orders
    },
    options () {
      if (!this.name) return this.orders
      const re = new RegExp(this.name, 'i')
      return this.orders.filter(i => {
        if (i.id.match(re)) return true
        if (i.invoiceId && i.invoiceId.match(re)) return true
        if (i.customer?.name && i.customer.name.match(re)) return true
        if (i.customer?.personalName && i.customer.personalName.match(re)) return true
        return false
      })
    },
  },
  created () {
    this.$store.dispatch('getOrders')
  },
  methods: {
    displayLabel (v) {
      let label = []
      if (v.invoiceId) label.push(`#${v.invoiceId}`)
      if (v.customer) label.push(v.customer.name)
      if (v.amount) label.push(`- $${v.amount}`)
      return label.join(' ')
    },
  },
}
</script>

<style lang="scss" scoped>
.item-value {
  display: block;
  padding-top: .35em;
}
</style>
