<template>
  <div>
    <b-field :message="stats.total | $(2, account.currency)">
      <b-radio-button
        v-model="accountId"
        v-for="account in data.accounts"
        :key="account.id"
        :native-value="account.id"
      >
        {{ account.name }}
      </b-radio-button>
    </b-field>

    <b-table
      :data="items"
      :row-class="rowClass"
      striped
      detailed
      @details-close="$emit('refresh')"
    >
      <b-table-column
        v-slot="props"
        label="Date"
        field="date"
        width="120"
        sortable
      >
        {{ props.row.date }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Amount"
        field="amount"
        width="100"
        numeric
        sortable
      >
        <span :class="{ 'has-text-success': props.row.amount > 0 }">
          {{ props.row.amount | $(2, account.currency) }}
        </span>
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Label"
        field="description"
      >
        {{ props.row.details }}
        <AccountingTxDetails :item="props.row" />
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Balance"
        width="100"
        numeric
      >
        {{ props.row.balance | $(2, account.currency) }}
      </b-table-column>

      <template #detail="props">
        <AccountingTxSplit
          :tx="props.row"
          :currency="account.currency"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import AccountingTxDetails from '@/components/Accounting/TxDetails.vue'
import AccountingTxSplit from '@/components/Accounting/TxSplit.vue'

export default {
  components: {
    AccountingTxDetails,
    AccountingTxSplit,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      accountId: '1',
    }
  },
  computed: {
    account () {
      return this.data.accounts.find(i => i.id === this.accountId)
    },
    items () {
      return this.data.accountTxes.filter(i => i.accountId === this.accountId)
    },
    stats () {
      return this.items.reduce((stats, item) => ({
        total: stats.total + item.amount,
      }), {
        total: 0,
      })
    },
  },
  methods: {
    rowClass (row) {
      return row.items.length === 0 && 'tr-hidden'
    },
  },
}
</script>

<style lang="scss">
.tr-hidden {
  opacity: .3;
}
</style>
