import gql from 'graphql-tag'

export default (opts) => ({
  apollo: {
    items: gql`query {
      items: ${opts.query}
    }`,
  },
  methods: {
    addItem () {
      this.$buefy.modal.open({
        parent: this,
        component: opts.modalComponent,
        hasModalCard: true,
        events: {
          refresh: () => this.$apollo.queries.items.refetch(),
        },
      })
    },
    editItem (id, clone = false) {
      const value = this.items.find(i => i.id === id)
      if (!value) {
        this.$buefy.toast.open({
          message: `Item #${id} not found`,
          type: 'is-danger',
        })
        return
      }
      delete value.__typename
      const modal = this.$buefy.modal.open({
        parent: this,
        component: opts.modalComponent,
        hasModalCard: true,
        props: { value },
        events: {
          refresh: () => this.$apollo.queries.items.refetch(),
          edit: async (id) => {
            await this.$apollo.queries.items.refetch()
            this.editItem(id)
          },
        },
      })
      if (clone)
        modal.$nextTick(() => {
          modal.$children[0].cloneItem()
        })
    },
    async removeItem (id) {
      if (!window.confirm('Delete this item?')) return
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation ($id: ID!) {
            ${opts.deleteMutationName}(id: $id)
          }`,
          variables: { id },
        })
        this.$buefy.toast.open({
          message: 'Item deleted',
          type: 'is-success',
        })
        this.$apollo.queries.items.refetch()
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
    },
  },
})
