<template>
  <div
    v-if="tx"
    class="card p-4"
  >
    <div class="columns">
      <div class="column is-narrow has-text-centered">
        <div class="is-size-7 greyed mb-5">
          #{{ tx.id }}
        </div>
        <strong>{{ tx.amount | $(2, tx.account.currency) }}</strong>
        <div class="is-size-7 mt-1">
          {{ tx.date }}
        </div>
        <a
          :href="'/accounting/transactions/' + tx.date.substr(0, 7)"
          class="is-size-7"
        >
          View transactions
        </a>
      </div>
      <div class="column">
        <div class="mb-4">
          <b-tag>Account: {{ tx.account.name }}</b-tag>
        </div>
        {{ tx.details }}
        <div
          v-if="tx.description"
          class="is-size-7 mt-1 mb-4"
        >
          {{ tx.description }}
        </div>
        <div
          v-if="tx.order"
          class="tags"
        >
          <b-tag type="is-info">
            Invoice #{{ tx.order.invoiceId }}
          </b-tag>
          <b-tag>
            {{ tx.order.customer.name }}
          </b-tag>
          <b-tag>
            {{ tx.order.amount | $ }}
          </b-tag>
        </div>
        <h2 class="is-size-5 mt-5 mb-1">
          Details
        </h2>
        <table class="table is-striped">
          <tbody>
            <tr
              v-for="item in tx.items"
              :key="item.id"
            >
              <td>{{ item.amount | $(2, tx.account.currency) }}</td>
              <td>{{ labelDetails(item) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      data: null,
    }
  },
  computed: {
    tx () {
      if (!this.data) return null
      return {
        ...this.data,
        items: this.data.amount < 0
          ? this.data.costs
          : this.data.incomes,
      }
    },
  },
  mounted () {
    this.fetchData()
    this.$store.dispatch('getOrders')
    this.$store.dispatch('getProducts')
    this.$store.dispatch('getProductFamilies')
  },
  methods: {
    async fetchData () {
      try {
        const { data } = await this.$apollo.query({
          query: gql`query ($id: ID!) {
            accountTx (id: $id) {
              id date description details amount orderId
              order {
                id invoiceNumber amount customer { name }
              }
              account {
                id name currency
              }
              incomes {
                id date description amount amountUsd category productId productFamilyId accountTxId
              }
              costs {
                id date description amount amountUsd category productId productFamilyId accountTxId
              }
            }
          }`,
          variables: {
            id: this.item.accountTxId,
          },
        })
        this.data = data.accountTx
      } catch (e) {
        console.log('e', e)
      }
    },
    labelDetails (item) {
      let label = []
      if (item.productFamilyId) {
        const family = this.$store.getters.productFamiliesById[item.productFamilyId]
        if (family) label.push(family.name)
      }
      if (item.productId) {
        const product = this.$store.getters.productsById[item.productId]
        if (product) label.push(product.name)
      }
      if (item.category) label.push(item.category)
      if (item.description) label.push(item.description)
      return label.join(' - ')
    },
  },
}
</script>

<style lang="scss" scoped>
.greyed {
  opacity: .5;
}
</style>
