<template>
  <div class="container">
    <b-button
      icon-left="plus"
      type="is-primary"
      class="is-pulled-right"
      rounded
      outlined
      @click="addItem"
    >
      Add
    </b-button>
    <b-button
      icon-left="plus"
      class="is-pulled-right mr-4"
      rounded
      outlined
      @click="addFamily"
    >
      Add Family
    </b-button>

    <h1 class="is-size-3">
      Products
    </h1>

    <b-switch
      v-model="showAll"
      size="is-small"
      class="mb-3"
    >
      Show all
    </b-switch>

    <div
      v-for="family in $store.state.productFamilies"
      v-show="productsByFamily[family.id] && productsByFamily[family.id].length > 0"
      :key="family.id"
    >
      <h2
        class="is-size-4 mt-4"
        style="cursor: pointer"
        @click="editFamily(family)"
      >
        {{ family.name }}
      </h2>

      <b-table
        :data="productsByFamily[family.id]"
        striped
        hoverable
      >
        <b-table-column
          v-slot="props"
          field="name"
        >
          <a
            :class="{ 'strike': !props.row.isAvailable }"
            @click="editItem(props.row.id)"
          >
            {{ props.row.name }}
          </a>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Cost"
          width="120"
          numeric
        >
          <span
            v-if="props.row.cost"
            class="money"
          >
            {{ props.row.cost | $(3) }}
          </span>
          <b-icon
            v-else
            icon="exclamation-triangle"
            type="is-warning"
          />
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Design"
          width="120"
          numeric
        >
          <span
            v-if="$store.getters.productDesignFeesTotal[props.row.id]"
            class="money"
          >
            {{ $store.getters.productDesignFeesTotal[props.row.id] | $(4) }}
          </span>
          <span
            v-else
            class="money"
          >
            -
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Price"
          width="120"
          numeric
        >
          <span
            v-if="minPrice[props.row.id]"
            class="money"
          >
            {{ minPrice[props.row.id] | $ }}
          </span>
          <span
            v-else
            class="money"
          >
            -
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="MSRP"
          width="120"
          numeric
        >
          <span
            v-if="props.row.msrp"
            class="money"
          >
            {{ props.row.msrp | $ }}
          </span>
          <span
            v-else
            class="money"
          >
            -
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          width="100"
        >
          <div class="buttons">
            <b-button
              size="is-small"
              icon-left="clone"
              type="is-info"
              outlined
              @click="editItem(props.row.id, true)"
            />
            <b-button
              size="is-small"
              icon-left="trash"
              type="is-danger"
              outlined
              @click="removeItem(props.row.id)"
            />
          </div>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'

import ViewMixin from './_mixin'

import ModalForm from '@/components/Modal/Product.vue'
import ModalFamily from '@/components/Modal/ProductFamily.vue'

export default {
  mixins: [ViewMixin({
    query: `products {
      id name isAvailable productFamilyId msrp cost
      prices { id moq amount }
      designFees { id designerFeeId qty }
    }`,
    modalComponent: ModalForm,
    deleteMutationName: 'productDelete',
  })],
  data () {
    return {
      showAll: false,
    }
  },
  computed: {
    productsByFamily () {
      const products = {}
      if (!this.items) return products
      this.items.forEach((item) => {
        if (!this.showAll && !item.isAvailable) return
        if (products[item.productFamilyId] === undefined)
          products[item.productFamilyId] = []
        products[item.productFamilyId].push(item)
      })
      return products
    },
    minPrice () {
      if (!this.items) return {}
      const minPrice = {}
      this.items.forEach(item => {
        if (item.prices.length === 0) return
        minPrice[item.id] = Math.min(...item.prices.map(i => i.amount))
      })
      return minPrice
    },
  },
  mounted () {
    this.$store.dispatch('getDesigners')
    this.$store.dispatch('getProducts')
    this.$store.dispatch('getProductFamilies')
  },
  methods: {
    addFamily () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalFamily,
        hasModalCard: true,
        events: {
          refresh: () => this.$store.dispatch('getProductFamilies', true),
        },
      })
    },
    editFamily (value) {
      delete value.__typename
      this.$buefy.modal.open({
        parent: this,
        component: ModalFamily,
        hasModalCard: true,
        props: { value },
        events: {
          refresh: () => this.$store.dispatch('getProductFamilies', true),
        },
      })
    },
    async removeFamily (id) {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation ($id: ID!) {
            productFamilyDelete(id: $id)
          }`,
          variables: { id },
        })
        this.$buefy.toast.open({
          message: 'Family deleted',
          type: 'is-success',
        })
        this.$store.dispatch('getProductFamilies', true)
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
span.money {
  opacity: .4;
  font-size: .9em;
}
a.strike {
  text-decoration: line-through;
}
</style>
