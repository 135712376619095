<template>
  <div>
    <span
      v-if="item"
      :class="{ 'is-size-7': size === 'is-small' }"
      class="item-value"
    >
      {{ item.fullLabel }}
      <span
        style="cursor: pointer"
        @click="$emit('input', null)"
      >
        <b-icon icon="times-circle" />
      </span>
    </span>
    <b-autocomplete
      v-else
      ref="autofocus"
      v-model="name"
      :custom-formatter="displayLabel"
      :data="options"
      :size="size"
      placeholder="Search designer fee by label"
      clearable
      open-on-focus
      keep-first
      @select="setValue"
    >
      <template #empty>
        No results found
      </template>
    </b-autocomplete>
  </div>
</template>

<script>
import AutocompleteMixin from './_mixin'

export default {
  mixins: [AutocompleteMixin],
  computed: {
    allOptions () {
      return Object.values(this.$store.getters.designerFeesById)
    },
    item () {
      if (!this.value) return null
      return this.allOptions.find(i => i.id === this.value)
    },
    options () {
      if (!this.name) return this.allOptions
      const re = new RegExp(this.name, 'i')
      return this.allOptions.filter(i => i.fullLabel.match(re))
    },
  },
  created () {
    this.$store.dispatch('getDesigners')
  },
  methods: {
    displayLabel (v) {
      return v.fullLabel
    },
  },
}
</script>

<style lang="scss" scoped>
.item-value {
  display: block;
  padding-top: .35em;
}
</style>
