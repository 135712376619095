<template>
  <div class="container">
    <AccountingUpload class="is-pulled-right" />

    <h1 class="is-size-3 mb-3">
      Accounting
    </h1>

    <MonthSelector
      v-model="month"
      class="is-pulled-right ml-5"
    />

    <b-tabs
      :value="tab"
      @input="changeTab"
    >
      <b-tab-item
        label="Transactions"
        value="transactions"
      >
        <AccountingTxes
          v-if="data"
          :data="data"
          @refresh="fetchData(true)"
        />
      </b-tab-item>
      <b-tab-item
        label="PnL"
        value="pnl"
      >
        <AccountingPnl
          v-if="data"
          :data="data"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import moment from 'moment'

import AccountingPnl from '@/components/Accounting/Pnl.vue'
import AccountingTxes from '@/components/Accounting/Txes.vue'
import AccountingUpload from '@/components/Accounting/Upload.vue'
import MonthSelector from '@/components/MonthSelector.vue'

export default {
  components: {
    AccountingPnl,
    AccountingTxes,
    AccountingUpload,
    MonthSelector,
  },
  data () {
    return {
      month: this.$route.params.month || moment().format('YYYY-MM'),
      tab: this.$route.params.tab || 'transactions',
      monthData: {},
    }
  },
  computed: {
    data () {
      return this.monthData[this.month] || null
    },
  },
  watch: {
    month () {
      this.fetchData()
      this.updateUrl()
    },
    tab () {
      this.updateUrl()
    },
  },
  mounted () {
    this.fetchData()
    this.$store.dispatch('getOrders')
    this.$store.dispatch('getProducts')
    this.$store.dispatch('getProductFamilies')
  },
  methods: {
    async fetchData (force = false) {
      if (!force && this.monthData[this.month] !== undefined) return
      try {
        const { data } = await this.$apollo.query({
          query: gql`query ($date: String) {
            incomes (date: $date) {
              id date description amount amountUsd category productId productFamilyId accountTxId
            }
            costs (date: $date) {
              id date description amount amountUsd category productId productFamilyId accountTxId
            }
            accountTxes (date: $date) {
              id date description details amount balance accountId orderId
            }
            accounts { id name currency}
          }`,
          variables: {
            date: this.month,
          },
          fetchPolicy: 'no-cache',
        })
        data.accountTxes.forEach((tx, i) => {
          if (tx.amount < 0)
            data.accountTxes[i].items = data.costs.filter(i => i.accountTxId === tx.id)
          else
            data.accountTxes[i].items = data.incomes.filter(i => i.accountTxId === tx.id)
        })
        this.$set(this.monthData, this.month, data)
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
    },
    changeTab (tab) {
      this.tab = tab
    },
    updateUrl () {
      this.$router.replace({
        path: `/accounting/${this.tab}/${this.month}`,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
