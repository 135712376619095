<template>
  <div>
    <span
      v-if="item"
      :class="{ 'is-size-7': size === 'is-small' }"
      class="item-value"
    >
      {{ item.name }}
      <span
        style="cursor: pointer"
        @click="$emit('input', null)"
      >
        <b-icon icon="times-circle" />
      </span>
    </span>
    <b-autocomplete
      v-else
      ref="autofocus"
      v-model="name"
      :custom-formatter="displayLabel"
      :data="options"
      :size="size"
      placeholder="Search customer by name"
      clearable
      open-on-focus
      keep-first
      @select="setValue"
    >
      <template #empty>
        No results found
      </template>
    </b-autocomplete>
  </div>
</template>

<script>
import AutocompleteMixin from './_mixin'

export default {
  mixins: [AutocompleteMixin],
  computed: {
    item () {
      if (!this.value) return null
      return this.$store.state.customers.find(i => i.id === this.value)
    },
    options () {
      if (!this.name) return this.$store.state.customers
      const re = new RegExp(this.name, 'i')
      return this.$store.state.customers.filter(i => i.name.match(re))
    },
  },
  created () {
    this.$store.dispatch('getCustomers')
  },
}
</script>

<style lang="scss" scoped>
.item-value {
  display: block;
  padding-top: .35em;
}
</style>
