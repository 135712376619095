<template>
  <form
    v-if="localValue"
    class="modal-card"
    @submit.prevent="saveChanges"
  >
    <div class="modal-card-body">
      <div class="columns">
        <div class="column">
          <b-field label="Family">
            <b-select v-model="localValue.productFamilyId">
              <option
                v-for="item in $store.state.productFamilies"
                :key="item.id"
                :value="item.id"
                v-text="item.name"
              />
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Name">
            <b-input
              ref="autofocus"
              v-model="localValue.name"
            />
          </b-field>
        </div>
      </div>

      <h2 class="is-size-3 mb-2">
        Pricing
      </h2>
      <div class="columns">
        <div class="column">
          <b-field label="MSRP">
            <b-input
              v-model="localValue.msrp"
              icon="dollar-sign"
            />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Cost">
            <b-input
              v-model="localValue.cost"
              icon="dollar-sign"
            />
          </b-field>
        </div>
      </div>
      <table class="table">
        <thead>
          <th />
          <th>MOQ</th>
          <th>Vendor Price</th>
          <th class="has-text-right">
            Vendor Profit
          </th>
          <th class="has-text-right">
            Our Profit
          </th>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in localValue.prices"
            :key="'price' + i"
          >
            <td>
              <b-button
                icon-left="trash"
                type="is-danger"
                size="is-small"
                outlined
                rounded
                @click="localValue.prices.splice(i, 1)"
              />
            </td>
            <td>
              <b-input
                v-model="item.moq"
                icon="layer-group"
              />
            </td>
            <td>
              <b-input
                v-model="item.amount"
                icon="dollar-sign"
              />
            </td>
            <td class="has-text-right profits">
              <em>{{ priceProfits[i].vendorPct }}%</em>
              {{ priceProfits[i].vendor | $ }}
            </td>
            <td class="has-text-right profits">
              <em>{{ priceProfits[i].ourPct }}%</em>
              <strong>{{ priceProfits[i].our | $ }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <b-button
        icon-left="plus"
        size="is-small"
        rounded
        @click="addProductPrice"
      >
        Add item
      </b-button>

      <h2 class="is-size-3 mb-2 mt-5">
        Design Fees
      </h2>
      <div
        v-for="(item, i) in localValue.designFees"
        :key="'item' + i"
        class="columns"
      >
        <div class="column is-narrow">
          <b-button
            icon-left="trash"
            type="is-danger"
            size="is-small"
            outlined
            rounded
            @click="localValue.designFees.splice(i, 1)"
          />
        </div>
        <div class="column">
          <AutocompleteDesignerFee
            v-model="item.designerFeeId"
            autofocus
          />
        </div>
        <div class="column is-one-fifth">
          <b-input
            v-model="item.qty"
            icon="times"
          />
        </div>
        <div class="column is-one-fifth has-text-right">
          {{ designFee(item) | $(4) }}
        </div>
      </div>
      <strong
        v-if="designFeesAmount"
        class="is-pulled-right"
      >
        Total: {{ designFeesAmount | $(4) }}
      </strong>
      <b-button
        icon-left="plus"
        size="is-small"
        rounded
        @click="addDesignFee"
      >
        Add item
      </b-button>
    </div>
    <footer
      class="modal-card-foot"
      style="justify-content: space-between"
    >
      <b-button
        :loading="isLoading"
        label="Save changes"
        type="is-primary"
        native-type="submit"
      />
      <b-switch v-model="localValue.isAvailable">
        Available
      </b-switch>
      <b-button
        label="Cancel"
        outlined
        @click="$parent.close()"
      />
    </footer>
  </form>
</template>

<script>
import Vue from 'vue'

import ModalMixin from './_mixin'

import AutocompleteDesignerFee from '@/components/Autocomplete/DesignerFee.vue'

export default {
  components: {
    AutocompleteDesignerFee,
  },
  mixins: [ModalMixin({
    defaultValue: {
      name: '',
      isAvailable: true,
      productFamilyId: 0,
      prices: [],
      designFees: [],
    },
    beforeClone: v => {
      v.prices.forEach((_p, i) => {
        Vue.delete(v.prices[i], 'id')
      })
      v.designFees.forEach((_p, i) => {
        Vue.delete(v.designFees[i], 'id')
      })
    },
    beforeSaveValue: v => ({
      ...v,
      msrp: Number(v.msrp) || null,
      cost: Number(v.cost) || null,
      prices: v.prices.map(i => ({
        ...i,
        moq: Number(i.moq) || null,
        amount: Number(i.amount) || null,
        __typename: undefined,
      })),
      designFees: v.designFees.map(i => ({
        ...i,
        qty: Number(i.qty) || null,
        __typename: undefined,
      })),
    }),
    mutationName: 'productCreateOrUpdate',
    mutationType: 'ProductInput',
  })],
  computed: {
    designFeesAmount () {
      return this.$store.getters.productDesignFeesTotal[this.localValue.id]
    },
    priceProfits () {
      return this.localValue.prices.map(i => {
        const msrp = Number(this.localValue.msrp) || null
        const amount = Number(i.amount) || null
        const cost = Number(this.localValue.cost) || null
        const profit = {
          vendor: null,
          vendorPct: null,
          our: null,
          ourPct: null,
        }
        if (msrp) {
          profit.vendor = msrp - amount
          profit.vendorPct = Math.round(100 * profit.vendor / amount)
        }
        if (cost) {
          const totalCost = cost + this.designFeesAmount
          profit.our = Math.round(100 * (amount - totalCost)) / 100
          profit.ourPct = Math.round(100 * profit.our / totalCost)
        }
        return profit
      })
    },
  },
  methods: {
    designFee (item) {
      const fee = this.$store.getters.designerFeesById[item.designerFeeId]
      const qty = Number(item.qty) || 0
      return fee ? fee.amount * qty : 0
    },
    addDesignFee () {
      this.localValue.designFees.push({
        id: null,
        designerFeeId: null,
        qty: 1,
      })
    },
    addProductPrice () {
      this.localValue.prices.push({
        id: null,
        moq: '',
        amount: '',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
td.profits {
  font-size: .8em;
  line-height: 1.6;
  em {
    display: block;
  }
}
</style>
