<template>
  <div class="container">
    <form @submit.prevent="login">
      <b-field label="Username">
        <b-input v-model="email" />
      </b-field>
      <b-field label="Password">
        <b-input
          v-model="password"
          type="password"
        />
      </b-field>
      <b-button
        native-type="submit"
        type="is-primary"
      >
        Sign in
      </b-button>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    async login () {
      try {
        await this.$store.dispatch('login', {
          email: this.email,
          password: this.password,
        })
        this.$router.push('/')
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  align-items: center;
}
form {
  width: 400px;
  margin: 0 auto;
}
</style>
