<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-field label="Group by">
          <b-radio-button
            v-model="localValue.groupBy"
            native-value="month"
          >
            Month
          </b-radio-button>
          <b-radio-button
            v-model="localValue.groupBy"
            native-value="quarter"
          >
            Quarter
          </b-radio-button>
          <b-radio-button
            v-model="localValue.groupBy"
            native-value="year"
          >
            Year
          </b-radio-button>
          <b-radio-button
            v-model="localValue.groupBy"
            native-value="all"
          >
            All
          </b-radio-button>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Customer">
          <AutocompleteCustomer v-model="localValue.customerId" />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Product Family">
          <AutocompleteProductFamily v-model="localValue.productFamilyId" />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Product">
          <AutocompleteProduct v-model="localValue.productId" />
        </b-field>
      </div>
    </div>
    <b-field>
      <b-checkbox
        v-model="localValue.showProfit"
        size="is-small"
      >
        Profit
      </b-checkbox>
      <b-checkbox
        v-model="localValue.showSales"
        size="is-small"
      >
        Sales
      </b-checkbox>
      <b-checkbox
        v-model="localValue.showCost"
        size="is-small"
      >
        Cost
      </b-checkbox>
      <b-checkbox
        v-model="localValue.showDesign"
        size="is-small"
      >
        Design
      </b-checkbox>
    </b-field>
  </div>
</template>

<script>
import AutocompleteCustomer from '@/components/Autocomplete/Customer.vue'
import AutocompleteProduct from '@/components/Autocomplete/Product.vue'
import AutocompleteProductFamily from '@/components/Autocomplete/ProductFamily.vue'

export default {
  components: {
    AutocompleteCustomer,
    AutocompleteProduct,
    AutocompleteProductFamily,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      localValue: JSON.parse(JSON.stringify(this.value)),
    }
  },
  watch: {
    localValue: {
      deep: true,
      handler () {
        this.$emit('input', JSON.parse(JSON.stringify(this.localValue)))
      },
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
