<template>
  <div class="container">
    <b-button
      icon-left="plus"
      type="is-primary"
      class="is-pulled-right"
      rounded
      outlined
      @click="addItem"
    >
      Add
    </b-button>

    <h1 class="is-size-3 mb-3">
      Customers
    </h1>

    <b-message v-if="items && items.length === 0">
      Nothing yet
    </b-message>
    <b-table
      v-else
      :data="items"
      striped
      hoverable
    >
      <b-table-column
        v-slot="props"
        field="name"
      >
        <a @click="editItem(props.row.id)">
          {{ props.row.name }}
        </a>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="country"
        label="Country"
        sortable
      >
        {{ props.row.country }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        width="10"
      >
        <div class="buttons">
          <b-button
            size="is-small"
            icon-left="trash"
            type="is-danger"
            outlined
            @click="removeItem(props.row.id)"
          />
        </div>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import ViewMixin from './_mixin'

import ModalForm from '@/components/Modal/Customer.vue'

export default {
  mixins: [ViewMixin({
    query: `
      customers {
        id name personalName vatId
        email phone address city postcode country state
        hasShippingDetails shippingEmail shippingPhone shippingAddress shippingCity shippingPostcode shippingCountry shippingState
      }`,
    modalComponent: ModalForm,
    deleteMutationName: 'customerDelete',
  })],
}
</script>

<style lang="scss" scoped>
</style>
