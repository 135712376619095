<template>
  <form
    v-if="localValue"
    class="modal-card"
    @submit.prevent="saveChanges"
  >
    <div class="modal-card-body">
      <b-field label="Name">
        <b-input
          ref="autofocus"
          v-model="localValue.name"
        />
      </b-field>

      <h2 class="is-size-3 mb-2 mt-5">
        Invoices
      </h2>
      <table class="table is-fullwidth">
        <thead>
          <th />
          <th>Amount</th>
          <th>Invoice #</th>
          <th>Invoice Date</th>
          <th>Payment Date</th>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in localValue.invoices"
            :key="'item' + i"
          >
            <td width="10">
              <b-button
                icon-left="trash"
                type="is-danger"
                size="is-small"
                outlined
                rounded
                @click="localValue.invoices.splice(i, 1)"
              />
            </td>
            <td width="130">
              <b-input
                v-model="item.amount"
                icon="dollar-sign"
              />
            </td>
            <td>
              <b-input
                v-model="item.invoiceNumber"
                placeholder="Enter invoice #"
              />
            </td>
            <td>
              <b-datepicker
                v-model="item.invoiceDate"
                icon="calendar"
              >
                <b-button
                  label="Clear"
                  type="is-danger"
                  size="is-small"
                  icon-left="times"
                  outlined
                  @click="item.invoiceDate = null"
                />
              </b-datepicker>
            </td>
            <td>
              <b-datepicker
                v-model="item.paymentDate"
                icon="calendar"
              >
                <b-button
                  label="Clear"
                  type="is-danger"
                  size="is-small"
                  icon-left="times"
                  outlined
                  @click="item.paymentDate = null"
                />
              </b-datepicker>
            </td>
          </tr>
        </tbody>
      </table>
      <b-button
        icon-left="plus"
        size="is-small"
        rounded
        @click="addInvoice"
      >
        Add item
      </b-button>

      <h2 class="is-size-3 mb-2 mt-5">
        Fees
      </h2>
      <table class="table is-fullwidth">
        <thead>
          <th />
          <th>Amount</th>
          <th>Label</th>
          <th>Start</th>
          <th>End</th>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in localValue.fees"
            :key="'item' + i"
          >
            <td width="10">
              <b-button
                icon-left="trash"
                type="is-danger"
                size="is-small"
                outlined
                rounded
                @click="localValue.fees.splice(i, 1)"
              />
            </td>
            <td width="130">
              <b-input
                v-model="item.amount"
                icon="dollar-sign"
              />
            </td>
            <td>
              <b-input
                v-model="item.label"
                placeholder="Enter a label"
              />
            </td>
            <td>
              <b-datepicker
                v-model="item.startDate"
                icon="calendar"
              >
                <b-button
                  label="Clear"
                  type="is-danger"
                  size="is-small"
                  icon-left="times"
                  outlined
                  @click="item.startDate = null"
                />
              </b-datepicker>
            </td>
            <td>
              <b-datepicker
                v-model="item.endDate"
                icon="calendar"
              >
                <b-button
                  label="Clear"
                  type="is-danger"
                  size="is-small"
                  icon-left="times"
                  outlined
                  @click="item.endDate = null"
                />
              </b-datepicker>
            </td>
          </tr>
        </tbody>
      </table>
      <b-button
        icon-left="plus"
        size="is-small"
        rounded
        @click="addFee"
      >
        Add item
      </b-button>
    </div>
    <footer
      class="modal-card-foot"
      style="justify-content: space-between"
    >
      <b-button
        :loading="isLoading"
        label="Save changes"
        type="is-primary"
        native-type="submit"
      />
      <b-button
        label="Cancel"
        outlined
        @click="$parent.close()"
      />
    </footer>
  </form>
</template>

<script>
import moment from 'moment'

import ModalMixin from './_mixin'

export default {
  mixins: [ModalMixin({
    defaultValue: {
      name: '',
      fees: [],
      invoices: [],
    },
    beforeSetValue: v => ({
      ...v,
      fees: v.fees.map(i => ({
        ...i,
        startDate: i.startDate
          ? moment(i.startDate, 'YYYY-MM-DD').toDate()
          : null,
        endDate: i.endDate
          ? moment(i.endDate, 'YYYY-MM-DD').toDate()
          : null,
      })),
      invoices: v.invoices.map(i => ({
        ...i,
        invoiceDate: i.invoiceDate
          ? moment(i.invoiceDate, 'YYYY-MM-DD').toDate()
          : null,
        paymentDate: i.paymentDate
          ? moment(i.paymentDate, 'YYYY-MM-DD').toDate()
          : null,
      })),
    }),
    beforeSaveValue: v => ({
      ...v,
      fees: v.fees.map(i => ({
        ...i,
        amount: Number(i.amount) || null,
        startDate: i.startDate
          ? moment(i.startDate).format('YYYY-MM-DD')
          : null,
        endDate: i.endDate
          ? moment(i.endDate).format('YYYY-MM-DD')
          : null,
        __typename: undefined,
      })),
      invoices: v.invoices.map(i => ({
        ...i,
        amount: Number(i.amount) || null,
        invoiceDate: i.invoiceDate
          ? moment(i.invoiceDate).format('YYYY-MM-DD')
          : null,
        paymentDate: i.paymentDate
          ? moment(i.paymentDate).format('YYYY-MM-DD')
          : null,
        __typename: undefined,
      })),
    }),
    mutationName: 'designerCreateOrUpdate',
    mutationType: 'DesignerInput',
  })],
  methods: {
    addFee () {
      this.localValue.fees.push({
        id: null,
        amount: '',
        label: '',
      })
    },
    addInvoice () {
      this.localValue.invoices.push({
        id: null,
        invoiceNumber: '',
        invoiceDate: null,
        amount: '',
        paymentDate: null,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-card {
  width: 900px;
}
</style>
