<template>
  <div class="tags mt-2">
    <b-tag v-if="invoiceId">
      Invoice #{{ invoiceId }}
    </b-tag>
    <b-tag
      v-for="(d, i) in details"
      v-show="!invoiceId || details.length === 1"
      :key="i"
    >
      <span v-if="d.amount">({{ d.amount | $(0) }})</span>
      {{ d.description }}
    </b-tag>
    <b-tag
      v-if="mismatch"
      type="is-warning"
    >
      <b-icon icon="exclamation-triangle" />
      Amounts mismatch
    </b-tag>
    <b-tag
      v-if="missing"
      type="is-warning"
    >
      {{ missing | $(0) }}
    </b-tag>
    <b-tag
      v-if="orderRatio"
      type="is-info is-light"
    >
      {{ orderRatio }}%
    </b-tag>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    order () {
      if (!this.item.orderId) return
      return this.$store.state.orders.find(i => i.id === this.item.orderId)
    },
    orderRatio () {
      if (!this.order || this.item.amount < 0) return
      if (this.order.amount - this.item.amount < 100) return
      return Math.round(100 * this.item.amount / this.order.amount)
    },
    invoiceId () {
      return this.order?.invoiceId
    },
    mismatch () {
      if (this.item.items.length === 0) return false
      const amount = Math.abs(this.item.amount)
      const itemsSum = this.item.items.reduce((sum, i) => sum + i.amount, 0)
      return Math.abs(itemsSum - amount) > 1
    },
    missing () {
      if (this.item.items.length === 0) return false
      if (this.item.items.length === 1 && this.details.length === 1) return false
      const amount = Math.abs(this.item.amount)
      const detailsSum = this.details.reduce((sum, i) => {
        if (!i.amount) return sum
        return sum + i.amount
      }, 0)
      if (Math.abs(amount - detailsSum) < 1) return
      return amount - detailsSum
    },
    details () {
      const details = []
      this.item.items.forEach((item) => {
        const description = []
        if (item.category)
          description.push(item.category)
        if (item.productFamilyId) {
          const productFamily = this.$store.getters.productFamiliesById[item.productFamilyId]
          if (productFamily) description.push(productFamily.name)
        }
        if (item.productId) {
          const product = this.$store.getters.productsById[item.productId]
          if (product) description.push(product.name)
        }
        if (item.description)
          description.push(item.description)
        if (description.length > 0)
          details.push({
            amount: this.item.items.length > 1 ? item.amount : undefined,
            description: description.join(' - '),
          })
      })
      return details
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
