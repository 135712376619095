<template>
  <div class="container">
    <b-dropdown
      :triggers="['hover']"
      class="is-pulled-right"
      position="is-bottom-left"
    >
      <template #trigger>
        <b-button
          type="is-primary"
          icon-left="edit"
          outlined
          @click="fakeInvoice()"
        >
          Fake invoice
        </b-button>
      </template>
      <b-dropdown-item
        v-for="(item, i) in storedItems"
        :key="'stored' + i + item.id"
        @click="fakeInvoice(item)"
      >
        {{ invoiceName(item) }}
        ({{ item.amount | $ }})
      </b-dropdown-item>
    </b-dropdown>

    <h1 class="is-size-3 mb-3">
      Invoices
    </h1>

    <b-message v-if="items && items.length === 0">
      Nothing yet
    </b-message>
    <b-table
      v-else
      :data="items"
      striped
      hoverable
    >
      <b-table-column
        v-slot="props"
        field="id"
        label="#"
        sortable
        searchable
      >
        <a @click="openInvoice(props.row.id)">
          {{ props.row.id }}
        </a>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="date"
        label="Date"
        sortable
        searchable
      >
        {{ props.row.date }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="amount"
        label="Amount"
      >
        {{ props.row.amount | $ }}
      </b-table-column>
      <b-table-column
        v-slot="props"
      >
        <b-tag v-if="props.row.orderId">
          Order #{{ props.row.orderId }}
        </b-tag>
        <b-tag v-if="props.row.extType === 'fkcustom'">
          FK {{ props.row.extId }}
        </b-tag>
        <b-tag v-if="props.row.extType === 'ursa'">
          URSA {{ props.row.extId.replace(/-.*$/, '') }}
        </b-tag>
        <b-tag v-if="props.row.extType === 'yuzu'">
          Yuzu {{ props.row.extId }}
        </b-tag>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="customerEmail"
        label="Email"
      >
        {{ props.row.customerEmail }}
      </b-table-column>
      <b-table-column
        v-slot="props"
      >
        <b-button
          type="is-primary"
          icon-left="edit"
          size="is-small"
          outlined
          @click="fakeInvoiceItem(props.row.id)"
        >
          Fake
        </b-button>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import store from 'store'

import ViewMixin from './_mixin'

import FakeInvoiceModal from '@/components/FakeInvoiceModal.vue'
import InvoiceModal from '@/components/InvoiceModal.vue'

export default {
  mixins: [ViewMixin({
    query: `invoices {
      id
      date shippingAddress billingAddress items amount
      orderId extType extId customerEmail isSent
    }`,
  })],
  data () {
    return {
      storedItems: [],
    }
  },
  watch: {
    items () {
      if (!this.$route.query.customId) return
      const item = this.items.find(i => i.extType === 'fkcustom' && i.extId === this.$route.query.customId)
      if (item)
        this.openInvoice(item.id)
      else
        this.$buefy.toast.open({
          message: 'Invoice not found!',
          type: 'is-danger,'
        })
    },
  },
  mounted () {
    this.getFakes()
  },
  methods: {
    invoiceName (item) {
      const [name] = item.shippingAddress.split('\n')
      return name
    },
    openInvoice (id) {
      this.$buefy.modal.open({
        parent: this,
        component: InvoiceModal,
        props: { id },
      })
    },
    getFakes () {
      this.storedItems = store.get('fakeinvoices', [])
    },
    async fakeInvoiceItem (id) {
      try {
        const { data } = await this.$apollo.query({
          query: gql`query ($id: ID!) {
            invoice (id: $id) {
              id date
              shippingAddress billingAddress customerEmail isSent
              items
            }
          }`,
          variables: { id },
          fetchPolicy: 'no-cache',
        })
        this.$buefy.modal.open({
          parent: this,
          component: FakeInvoiceModal,
          props: {
            prefill: {
              ...data.invoice,
              items: JSON.parse(data.invoice.items),
            },
          },
          events: {
            close: () => {
              this.getFakes()
            },
          },
        })
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
    },
    fakeInvoice (prefill = {}) {
      this.$buefy.modal.open({
        parent: this,
        component: FakeInvoiceModal,
        props: { prefill },
        events: {
          close: () => {
            this.getFakes()
          },
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
