<template>
  <div class="container">
    <b-button
      icon-left="plus"
      type="is-primary"
      class="is-pulled-right"
      rounded
      outlined
      @click="addItem"
    >
      Add
    </b-button>

    <h1 class="is-size-3 mb-3">
      Orders
    </h1>

    <div class="columns">
      <div class="column">
        <b-field label="Customer">
          <AutocompleteCustomer v-model="filters.customerId" />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Product Family">
          <AutocompleteProductFamily v-model="filters.productFamilyId" />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Product">
          <AutocompleteProduct v-model="filters.productId" />
        </b-field>
      </div>
    </div>

    <b-message v-if="filteredItems && filteredItems.length === 0">
      Nothing yet
    </b-message>
    <b-table
      v-else
      :data="filteredItems"
      striped
      hoverable
    >
      <b-table-column
        v-slot="props"
        field="invoiceDate"
        label="Date"
      >
        <a @click="editItem(props.row.id)">
          {{ props.row.invoiceDate }}
        </a>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="customerId"
        label="Customer"
      >
        {{ customerName(props.row.customerId) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="amount"
        label="Amount"
      >
        {{ props.row.amount | $ }}
      </b-table-column>
      <b-table-column
        v-slot="props"
      >
        <b-button
          :disabled="!props.row.invoiceId"
          type="is-info"
          size="is-small"
          icon-left="paper-plane"
          rounded
          outlined
          @click="openInvoice(props.row.invoiceId)"
        >
          Invoice
        </b-button>
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="TXs"
      >
        <div
          class="clickable"
          @click="showAccountTxes = props.row.id"
        >
          <b-tag
            v-if="props.row.accountTxes.length > 0"
            rounded
          >
            {{ props.row.accountTxes.length }}
          </b-tag>
          <b-tag
            v-else
            :type="props.row.amount > 200 ? 'is-warning' : undefined"
            rounded
          >
            <b-icon icon="exclamation-triangle" />
          </b-tag>
          <small
            v-if="props.row.txTotal && props.row.txTotalDiff !== 0"
            :class="cssClassDiff(props.row.txTotalDiff)"
            class="ml-2"
          >
            {{ props.row.txTotalDiff | $ }}
          </small>
        </div>
      </b-table-column>
      <b-table-column
        v-slot="props"
        width="10"
      >
        <div class="buttons">
          <b-button
            size="is-small"
            icon-left="trash"
            type="is-danger"
            outlined
            @click="removeItem(props.row.id)"
          />
        </div>
      </b-table-column>
    </b-table>
    <b-modal
      :active="orderModal"
      @close="showAccountTxes = null"
    >
      <table
        v-if="orderModal"
        class="table is-striped"
      >
        <thead>
          <tr>
            <th width="120">
              Date
            </th>
            <th width="120">
              Amount
            </th>
            <th>Description</th>
            <th width="120">
              Account
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="tx in orderModal.accountTxes"
            :key="tx.id"
          >
            <td>{{ tx.date }}</td>
            <td>{{ tx.credit || -tx.debit | $ }}</td>
            <td>
              {{ tx.details }}
              <div class="is-size-7">
                {{ tx.description }}
              </div>
            </td>
            <td>{{ accountName(tx.accountId) }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="4">
              Total: {{ orderModal.txTotal | $ }}
              <b-tag
                v-if="orderModalDiff"
                type="is-warning"
                class="ml-2"
              >
                Diff:
                {{ orderModalDiff | $ }}
              </b-tag>
            </th>
          </tr>
        </tfoot>
      </table>
    </b-modal>
  </div>
</template>

<script>
import ViewMixin from './_mixin'

import AutocompleteCustomer from '@/components/Autocomplete/Customer.vue'
import AutocompleteProduct from '@/components/Autocomplete/Product.vue'
import AutocompleteProductFamily from '@/components/Autocomplete/ProductFamily.vue'
import InvoiceModal from '@/components/InvoiceModal.vue'
import ModalForm from '@/components/Modal/Order.vue'

export default {
  components: {
    AutocompleteCustomer,
    AutocompleteProduct,
    AutocompleteProductFamily,
  },
  mixins: [ViewMixin({
    query: `orders {
      id customerId
      invoiceId invoiceDate
      amount shippingAmount discountAmount discountLabel
      items {
        id productId qty unitPrice
      }
      accountTxes {
        date description details debit credit accountId
      }
    }`,
    modalComponent: ModalForm,
    deleteMutationName: 'orderDelete',
  })],
  data () {
    return {
      filters: {
        customerId: null,
        productId: null,
        productFamilyId: null,
      },
      showAccountTxes: null,
    }
  },
  computed: {
    orderModal () {
      if (!this.showAccountTxes) return null
      return this.filteredItems.find(i => i.id === this.showAccountTxes)
    },
    orderModalDiff () {
      return this.orderModal.amount - this.orderModal.txTotal
    },
    filteredItems () {
      if (!this.items) return this.items

      const productIds = []
      if (this.filters.productId) productIds.push(this.filters.productId)
      else if (this.filters.productFamilyId) {
        this.$store.state.products.forEach((product) => {
          if (product.family.id === this.filters.productFamilyId)
            productIds.push(product.id)
        })
      }

      return this.items.filter((item) => {
        if (this.filters.customerId && item.customerId !== this.filters.customerId) return false
        if (productIds.length && !item.items.find(i => productIds.indexOf(i.productId) !== -1)) return false
        return true
      }).map(i => ({
        ...i,
        txTotal: this.txTotal(i),
        txTotalDiff: this.txTotal(i) - i.amount,
      }))
    },
  },
  mounted () {
    this.$store.dispatch('getCustomers')
    this.$store.dispatch('getProducts')
    this.$store.dispatch('getAccounts')
  },
  methods: {
    customerName(id) {
      if (!this.$store.getters.customersById[id]) return '???'
      return this.$store.getters.customersById[id].name
    },
    accountName(id) {
      if (!this.$store.getters.accountsById[id]) return '???'
      return this.$store.getters.accountsById[id].name
    },
    txTotal (item) {
      return item.accountTxes.reduce((sum, tx) => sum + tx.credit - tx.debit, 0)
    },
    cssClassDiff (diff) {
      if (diff > 0) return 'has-text-success'
      if (diff < -50) return 'has-text-danger has-text-weight-bold'
    },
    openInvoice (id) {
      this.$buefy.modal.open({
        parent: this,
        component: InvoiceModal,
        props: { id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}
</style>
