import gql from 'graphql-tag'

export default (opts) => ({
  props: {
    value: {
      type: Object,
      default: () => opts.defaultValue,
    },
  },
  data () {
    let localValue = JSON.parse(JSON.stringify(this.value))
    if (opts.beforeSetValue)
      localValue = opts.beforeSetValue(localValue)
    return {
      localValue,
      isLoading: false,
    }
  },
  mounted() {
    if (this.$refs.autofocus)
      this.$refs.autofocus.focus()
  },
  methods: {
    async saveChanges () {
      this.isLoading = true
      try {
        const item = opts.beforeSaveValue
          ? opts.beforeSaveValue(this.localValue)
          : this.localValue
        const { data } = await this.$apollo.mutate({
          mutation: gql`mutation ($item: ${opts.mutationType}!) {
            ${opts.mutationName}(item: $item) { id }
          }`,
          variables: { item },
        })
        const id = data[opts.mutationName].id
        this.$buefy.toast.open({
          message: 'Changes saved',
          type: 'is-success',
        })
        this.$emit('refresh')
        this.$parent.close()
        return id
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
      this.isLoading = false
    },
    async cloneItem () {
      try {
        delete this.localValue.id
        if (opts.beforeClone) {
          opts.beforeClone(this.localValue)
        }
        const id = await this.saveChanges()
        this.$emit('edit', id)
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
    },
  },
})
