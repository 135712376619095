<template>
  <div>
    <b-table
      v-if="filters && items"
      :data="items"
      striped
      hoverable
      detailed
    >
      <b-table-column
        v-slot="props"
        label="Period"
        field="period"
      >
        {{ props.row.period }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        :visible="filters.showProfit"
        label="Profit"
        field="profitAmount"
        numeric
        sortable
      >
        <strong>{{ props.row.profitAmount | $ }}</strong>
      </b-table-column>
      <b-table-column
        v-slot="props"
        :visible="filters.showProfit && filters.showSales"
        width="20"
      >
        <span class="percent">{{ profitPercent(props.row) }}</span>
      </b-table-column>
      <b-table-column
        v-slot="props"
        :visible="filters.showSales"
        label="Sales"
        field="salesAmount"
        numeric
        sortable
      >
        {{ props.row.salesAmount | $ }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        :visible="filters.showCost"
        label="Cost"
        field="costAmount"
        numeric
        sortable
      >
        {{ props.row.costAmount | $ }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        :visible="filters.showDesign"
        label="Design"
        field="designAmount"
        numeric
        sortable
      >
        {{ props.row.designAmount | $ }}
      </b-table-column>
      <template #detail="props">
        <!-- PRODUCT FAMILIES -->
        <h2 class="is-size-4 mb-3">
          Summary
        </h2>
        <b-table
          :data="Object.values(props.row.productFamilies)"
          striped
        >
          <b-table-column
            v-slot="propsItem"
            label="Name"
            field="name"
          >
            {{ propsItem.row.name }}
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showProfit"
            label="Profit"
            field="profitAmount"
            numeric
            sortable
          >
            <strong>{{ propsItem.row.profitAmount | $ }}</strong>
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showProfit"
            width="20"
          >
            <span class="percent">{{ profitPercent(propsItem.row) }}</span>
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showSales"
            label="Sales"
            field="salesAmount"
            numeric
            sortable
          >
            {{ propsItem.row.salesAmount | $ }}
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showCost"
            label="Cost"
            field="costAmount"
            numeric
            sortable
          >
            {{ propsItem.row.costAmount | $ }}
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showDesign"
            label="Design"
            field="designAmount"
            numeric
            sortable
          >
            {{ propsItem.row.designAmount | $ }}
          </b-table-column>
        </b-table>

        <!-- CUSTOMERS -->
        <h2 class="is-size-4 mb-3 mt-5">
          Customers
        </h2>
        <b-table
          :data="Object.values(props.row.customers)"
          :default-sort="['salesAmount', 'desc']"
          striped
        >
          <b-table-column
            v-slot="propsItem"
            label="Name"
            field="name"
          >
            {{ propsItem.row.name }}
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showProfit"
            label="Profit"
            field="profitAmount"
            numeric
            sortable
          >
            <strong>{{ propsItem.row.profitAmount | $ }}</strong>
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showProfit"
            width="20"
          >
            <span class="percent">{{ profitPercent(propsItem.row) }}</span>
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showSales"
            label="Sales"
            field="salesAmount"
            numeric
            sortable
          >
            {{ propsItem.row.salesAmount | $ }}
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showCost"
            label="Cost"
            field="costAmount"
            numeric
            sortable
          >
            {{ propsItem.row.costAmount | $ }}
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showDesign"
            label="Design"
            field="designAmount"
            numeric
            sortable
          >
            {{ propsItem.row.designAmount | $ }}
          </b-table-column>
        </b-table>

        <!-- PRODUCTS -->
        <h2 class="is-size-4 mb-3 mt-5">
          Products
        </h2>
        <b-table
          :data="Object.values(props.row.products)"
          :default-sort="['name', 'asc']"
          striped
        >
          <b-table-column
            v-slot="propsItem"
            field="qty"
            width="80"
            numeric
          >
            <small>{{ propsItem.row.qty }}</small>
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            label="Name"
            field="name"
            sortable
          >
            {{ propsItem.row.name }}
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showProfit"
            label="Profit"
            field="profitAmount"
            numeric
            sortable
          >
            <strong>{{ propsItem.row.profitAmount | $ }}</strong>
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showProfit"
            width="20"
          >
            <span class="percent">{{ profitPercent(propsItem.row) }}</span>
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showSales"
            label="Sales"
            field="salesAmount"
            numeric
            sortable
          >
            {{ propsItem.row.salesAmount | $ }}
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showCost"
            label="Cost"
            field="costAmount"
            numeric
            sortable
          >
            {{ propsItem.row.costAmount | $ }}
          </b-table-column>
          <b-table-column
            v-slot="propsItem"
            :visible="filters.showDesign"
            label="Design"
            field="designAmount"
            numeric
            sortable
          >
            {{ propsItem.row.designAmount | $ }}
          </b-table-column>
        </b-table>
      </template>
    </b-table>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import moment from 'moment'

export default {
  props: {
    filters: {
      type: Object,
      default: () => null,
    },
  },
  apollo: {
    orders: gql`query {
      orders {
        invoiceDate customerId
        items { productId qty unitPrice }
      }
    }`,
  },
  computed: {
    reportData () {
      const data = {}
      if (!this.orders || !this.filters) return data
      this.orders
        .filter(order => {
          if (this.filters.customerId && order.customerId !== this.filters.customerId) return false
          return true
        })
        .forEach(order => {
          let datePeriodFormat = 'MM/YYYY'
          if (this.filters) switch (this.filters.groupBy) {
            case 'quarter':
              datePeriodFormat = 'YYYY [Q]Q'
              break
            case 'year':
              datePeriodFormat = 'YYYY'
              break
            case 'all':
              datePeriodFormat = 'N'
              break
          }
          const datePeriod = moment(order.invoiceDate).format(datePeriodFormat)
          if (!datePeriod) return
          if (data[datePeriod] === undefined) data[datePeriod] = {
            salesAmount: 0,
            costAmount: 0,
            designAmount: 0,
            profitAmount: 0,
            products: {},
            productFamilies: {},
            customers: {},
          }
          order.items.forEach(item => {
            const product = this.$store.getters.productsById[item.productId]
            if (!product || !order.customerId) return

            // filter by product
            if (this.filters.productId && product.id !== this.filters.productId) return false

            // filter by product family
            if (this.filters.productFamilyId && product.family.id !== this.filters.productFamilyId) return false

            // product details
            if (data[datePeriod].products[product.id] === undefined)
              data[datePeriod].products[product.id] = {
                name: product && product.family
                  ? `${product.family.name} - ${product.name}`
                  : '???',
                qty: 0,
                salesAmount: 0,
                costAmount: 0,
                designAmount: 0,
                profitAmount: 0,
              }
            if (data[datePeriod].productFamilies[product.family.id] === undefined) {
              data[datePeriod].productFamilies[product.family.id] = {
                name: product && product.family
                  ? product.family.name
                  : '???',
                salesAmount: 0,
                costAmount: 0,
                designAmount: 0,
                profitAmount: 0,
              }
            }
            // customer details
            if (data[datePeriod].customers[order.customerId] === undefined) {
              const customer = this.$store.getters.customersById[order.customerId]
              const name = customer ? customer.name : '???';
              data[datePeriod].customers[order.customerId] = {
                name,
                salesAmount: 0,
                costAmount: 0,
                designAmount: 0,
                profitAmount: 0,
              }
            }

            // sales
            const qty = Number(item.qty) || 0
            const unitPrice = Number(item.unitPrice) || 0
            data[datePeriod].salesAmount += qty * unitPrice
            data[datePeriod].products[product.id].salesAmount += qty * unitPrice
            data[datePeriod].productFamilies[product.family.id].salesAmount += qty * unitPrice
            data[datePeriod].customers[order.customerId].salesAmount += qty * unitPrice
            // cost
            const cost = product.cost || 0
            data[datePeriod].costAmount += qty * cost
            data[datePeriod].products[product.id].costAmount += qty * cost
            data[datePeriod].productFamilies[product.family.id].costAmount += qty * cost
            data[datePeriod].customers[order.customerId].costAmount += qty * cost
            // design fees
            const designFees = this.$store.getters.productDesignFees({
              productId: product.id,
              date: order.invoiceDate,
            }).reduce((total, fee) => total + fee.qty * fee.amount, 0)
            data[datePeriod].designAmount += qty * designFees
            data[datePeriod].products[product.id].designAmount += qty * designFees
            data[datePeriod].productFamilies[product.family.id].designAmount += qty * designFees
            data[datePeriod].customers[order.customerId].designAmount += qty * designFees
            // profit
            const unitProfit = unitPrice - cost - designFees
            data[datePeriod].profitAmount += qty * unitProfit
            data[datePeriod].products[product.id].profitAmount += qty * unitProfit
            data[datePeriod].productFamilies[product.family.id].profitAmount += qty * unitProfit
            data[datePeriod].customers[order.customerId].profitAmount += qty * unitProfit
            // qty
            data[datePeriod].products[product.id].qty += qty
          })
        })
      return data
    },
    items () {
      return Object.keys(this.reportData).map(key => ({
        period: key,
        ...this.reportData[key],
      }))
    },
  },
  created () {
    this.$store.dispatch('getCustomers')
    this.$store.dispatch('getDesigners')
    this.$store.dispatch('getProducts', true)
    this.$store.dispatch('getProductFamilies')
  },
  methods: {
    profitPercent (row) {
      const pct = Math.round(100 * row.profitAmount / row.salesAmount)
      return pct ? `${pct}%` : ''
    },
  },
}
</script>

<style lang="scss" scoped>
span.percent {
  opacity: .4;
  font-size: .9em;
}
</style>
