<template>
  <div>
    <div
      v-if="filters.startDate"
      class="is-size-7 mb-1"
    >
      From
      {{ filters.startDate }}
      to
      {{ filters.endDate }}
    </div>
    <table
      v-if="reportData"
      class="table is-striped"
    >
      <thead>
        <tr>
          <th>Name</th>
          <th />
          <th>Revenue</th>
          <th>Costs</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in reportData.byFamily"
          v-show="item.sumIncomes || item.sumCosts"
          :key="'family' + item.id"
        >
          <td>{{ item.name }}</td>
          <td :class="cssClass(item)">
            {{ item.sumIncomes - item.sumCosts | $(0) }}
          </td>
          <td
            class="clickable"
            @click="showModal(item.incomes)"
          >
            {{ item.sumIncomes | $(0) }}
          </td>
          <td
            class="clickable"
            @click="showModal(item.costs)"
          >
            {{ item.sumCosts | $(0) }}
          </td>
        </tr>
        <tr>
          <th>Subtotal</th>
          <th :class="cssClass(reportData.totalProducts)">
            {{ reportData.totalProducts.incomes - reportData.totalProducts.costs | $(0) }}
          </th>
          <th>{{ reportData.totalProducts.incomes | $(0) }}</th>
          <th>{{ reportData.totalProducts.costs | $(0) }}</th>
        </tr>
        <tr
          v-for="(item, name) in reportData.byCategory"
          v-show="item.sumIncomes || item.sumCosts"
          :key="'category' + name"
        >
          <td>{{ name }}</td>
          <td :class="cssClass(item)">
            {{ item.sumIncomes - item.sumCosts | $(0) }}
          </td>
          <td
            class="clickable"
            @click="showModal(item.incomes)"
          >
            {{ item.sumIncomes | $(0) }}
          </td>
          <td
            class="clickable"
            @click="showModal(item.costs)"
          >
            {{ item.sumCosts | $(0) }}
          </td>
        </tr>
        <tr>
          <th>Subtotal</th>
          <th :class="cssClass(reportData.totalCategories)">
            {{ reportData.totalCategories.incomes - reportData.totalCategories.costs | $(0) }}
          </th>
          <th>{{ reportData.totalCategories.incomes | $(0) }}</th>
          <th>{{ reportData.totalCategories.costs | $(0) }}</th>
        </tr>
        <tr
          v-for="(item, name) in reportData.dividendsByPerson"
          v-show="item.sumIncomes || item.sumCosts"
          :key="'person' + name"
        >
          <td>{{ name }}</td>
          <td :class="cssClass(item)">
            {{ item.sumIncomes - item.sumCosts | $(0) }}
          </td>
          <td
            class="clickable"
            @click="showModal(item.incomes)"
          >
            {{ item.sumIncomes | $(0) }}
          </td>
          <td
            class="clickable"
            @click="showModal(item.costs)"
          >
            {{ item.sumCosts | $(0) }}
          </td>
        </tr>
        <tr>
          <th>Dividends</th>
          <th
            class="clickable"
            :class="cssClass(reportData.dividends)"
          >
            {{ reportData.dividends.sumIncomes - reportData.dividends.sumCosts | $(0) }}
          </th>
          <th
            class="clickable"
            @click="showModal(reportData.dividends.incomes)"
          >
            {{ reportData.dividends.sumIncomes | $(0) }}
          </th>
          <th
            class="clickable"
            @click="showModal(reportData.dividends.costs)"
          >
            {{ reportData.dividends.sumCosts | $(0) }}
          </th>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th>Total</th>
          <th :class="cssClass(reportData.total)">
            {{ reportData.total.incomes - reportData.total.costs | $(0) }}
          </th>
          <th>{{ reportData.total.incomes | $(0) }}</th>
          <th>{{ reportData.total.costs | $(0) }}</th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import moment from 'moment'

import PnlModal from '@/components/Accounting/PnlModal.vue'

export default {
  props: {
    filters: {
      type: Object,
      default: () => null,
    },
  },
  apollo: {
    costs: gql`query {
      costs {
        id date description amountUsd category productId productFamilyId accountTxId
        accountTx {
          description details
          account { name }
        }
      }
    }`,
    incomes: gql`query {
      incomes {
        id date description amountUsd category productId productFamilyId accountTxId
        accountTx {
          description details
          account { name }
        }
      }
    }`,
  },
  computed: {
    startDate () {
      return moment(this.filters.startDate)
    },
    endDate () {
      return moment(this.filters.endDate)
    },
    reportData () {
      const data = {
        // group by family
        byFamily: {},
        totalProducts: {
          costs: 0,
          incomes: 0,
        },
        // group by category
        byCategory: {},
        totalCategories: {
          costs: 0,
          incomes: 0,
        },
        // dividends
        dividendsByPerson: {},
        dividends: {
          sumCosts: 0,
          sumIncomes: 0,
          costs: [],
          incomes: [],
        },
        // total
        total: {
          costs: 0,
          incomes: 0,
        },
      }
      if (this.$store.state.productFamilies.length === 0) return
      this.$store.state.productFamilies.forEach((family) => {
        data.byFamily[family.id] = {
          id: family.id,
          name: family.name,
          costs: [],
          incomes: [],
          sumCosts: 0,
          sumIncomes: 0
        }
      })
      if (!this.costs) return
      this.costs.forEach(item => {
        if (!this.isInPeriod(item)) return
        data.total.costs += item.amountUsd
        if (item.category?.startsWith('Dividends')) {
          data.dividends.sumCosts += item.amountUsd
          data.dividends.costs.push(item)
          const person = item.category.split(' ')[1]
          if (person) {
            if (data.dividendsByPerson[person] === undefined)
              data.dividendsByPerson[person] = {
                costs: [],
                incomes: [],
                sumCosts: 0,
                sumIncomes: 0
              }
            data.dividendsByPerson[person].costs.push(item)
            data.dividendsByPerson[person].sumCosts += item.amountUsd
          }
        } else if (item.productFamilyId) {
          data.totalProducts.costs += item.amountUsd
          data.byFamily[item.productFamilyId].costs.push(item)
          data.byFamily[item.productFamilyId].sumCosts += item.amountUsd
        } else {
          const category = item.category || '0'
          data.totalCategories.costs += item.amountUsd
          if (data.byCategory[category] === undefined)
            data.byCategory[category] = {
              costs: [],
              incomes: [],
              sumCosts: 0,
              sumIncomes: 0
            }
          data.byCategory[category].costs.push(item)
          data.byCategory[category].sumCosts += item.amountUsd
        }
      })
      if (!this.incomes) return
      this.incomes.forEach(item => {
        if (!this.isInPeriod(item)) return
        data.total.incomes += item.amountUsd
        if (item.category?.startsWith('Dividends')) {
          data.dividends.sumIncomes += item.amountUsd
          data.dividends.incomes.push(item)
          const person = item.category.split(' ')[1]
          if (person) {
            if (data.dividendsByPerson[person] === undefined)
              data.dividendsByPerson[person] = {
                costs: [],
                incomes: [],
                sumCosts: 0,
                sumIncomes: 0
              }
            data.dividendsByPerson[person].incomes.push(item)
            data.dividendsByPerson[person].sumIncomes += item.amountUsd
          }
        } else if (item.productFamilyId) {
          data.totalProducts.incomes += item.amountUsd
          data.byFamily[item.productFamilyId].incomes.push(item)
          data.byFamily[item.productFamilyId].sumIncomes += item.amountUsd
        } else {
          const category = item.category || '0'
          data.totalCategories.incomes += item.amountUsd
          if (data.byCategory[category] === undefined)
            data.byCategory[category] = {
              costs: [],
              incomes: [],
              sumCosts: 0,
              sumIncomes: 0
            }
          data.byCategory[category].incomes.push(item)
          data.byCategory[category].sumIncomes += item.amountUsd
        }
      })
      return data
    },
  },
  created () {
    this.$store.dispatch('getProducts', true)
    this.$store.dispatch('getProductFamilies')
  },
  methods: {
    cssClass (item) {
      let diff = 0
      if (item.sumIncomes !== undefined)
        diff = item.sumIncomes - item.sumCosts
      else if (item.incomes !== undefined)
        diff = item.incomes - item.costs
      if (diff < 0) return 'has-text-danger'
      if (diff > 0) return 'has-text-success'
    },
    isInPeriod (item) {
      if (!this.filters.startDate) return true
      return this.filters.startDate <= item.date && item.date <= this.filters.endDate
    },
    showModal (items) {
      if (items.length === 0) {
        this.$buefy.toast.open({
          message: 'Nothing to see here!',
        })
        return
      }
      this.$buefy.modal.open({
        parent: this,
        component: PnlModal,
        props: {
          items: items.sort((a, b) => a.date < b.date ? 1 : -1)
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
span.percent {
  opacity: .4;
  font-size: .9em;
}
.clickable {
  cursor: pointer;
}
</style>
