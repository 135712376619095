<template>
  <div id="app">
    <b-navbar v-if="$store.state.token">
      <template #start>
        <b-navbar-item
          tag="router-link"
          to="/orders"
          :active="!!$route.path.match(/^\/orders/)"
        >
          Orders
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          to="/products"
          :active="!!$route.path.match(/^\/products/)"
        >
          Products
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          to="/customers"
          :active="!!$route.path.match(/^\/customers/)"
        >
          Customers
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          to="/invoices"
          :active="!!$route.path.match(/^\/invoices/)"
        >
          Invoices
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          to="/designers"
          :active="!!$route.path.match(/^\/designers/)"
        >
          Designers
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          to="/reports/SalesByPeriod"
          :active="!!$route.path.match(/^\/reports\/SalesByPeriod/)"
        >
          Sales
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          to="/accounting"
          :active="!!$route.path.match(/^\/accounting/)"
        >
          Accounting
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          to="/reports/Pnl"
          :active="!!$route.path.match(/^\/reports\/Pnl/)"
        >
          PnL
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          to="/forecast"
          :active="!!$route.path.match(/^\/forecast/)"
        >
          Forecast
        </b-navbar-item>
      </template>
      <template #end>
        <b-navbar-item
          @click="logout"
        >
          Sign out
        </b-navbar-item>
      </template>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
export default {
  created () {
    if (!this.$store.state.token) this.$router.push('/login')
  },
  methods: {
    logout () {
      this.$store.commit('TOKEN_LOGOUT')
      this.$router.push('/login')
    },
  },
}
</script>

<style lang="scss">
html, body, #app {
  height: 100%;
}
</style>
