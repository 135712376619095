<template>
  <form
    v-if="localValue"
    class="modal-card"
    @submit.prevent="saveChanges"
  >
    <div
      class="modal-card-body"
      style="padding-bottom: 70px"
    >
      <div class="columns">
        <div class="column is-one-fifth">
          <b-field label="Amount">
            <b-input
              ref="autofocus"
              v-model="localValue.amount"
            />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Description">
            <b-input v-model="localValue.description" />
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-narrow">
          <b-field label="Month">
            <MonthSelector v-model="localValue.month" />
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-field label="Type">
            <b-radio-button
              v-model="localValue.txType"
              native-value="debit"
              type="is-danger"
              outlined
            >
              Debit
            </b-radio-button>
            <b-radio-button
              v-model="localValue.txType"
              native-value="credit"
              type="is-success"
              outlined
            >
              Credit
            </b-radio-button>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Product Family">
            <AutocompleteProductFamily v-model="localValue.productFamilyId" />
          </b-field>
        </div>
      </div>
    </div>
    <footer
      class="modal-card-foot"
      style="justify-content: space-between"
    >
      <b-button
        :loading="isLoading"
        label="Save changes"
        type="is-primary"
        native-type="submit"
      />
      <b-button
        label="Cancel"
        outlined
        @click="$parent.close()"
      />
    </footer>
  </form>
</template>

<script>
import moment from 'moment'

import ModalMixin from './_mixin'

import AutocompleteProductFamily from '@/components/Autocomplete/ProductFamily.vue'
import MonthSelector from '@/components/MonthSelector.vue'

export default {
  components: {
    AutocompleteProductFamily,
    MonthSelector,
  },
  mixins: [ModalMixin({
    defaultValue: {
      month: moment().format('YYYY-MM-DD'),
      description: '',
      amount: '',
      productFamilyId: null,
    },
    beforeSetValue: v => ({
      ...v,
      txType: v.amount < 0 ? 'debit' : 'credit',
      amount: Math.abs(v.amount),
    }),
    beforeSaveValue: v => ({
      ...v,
      month: v.month.substr(0, 7) + '-01',
      amount: v.amount
        ? Number(v.amount) * (v.txType === 'debit' ? -1 : 1)
        : null,
      txType: undefined,
    }),
    mutationName: 'forecastCreateOrUpdate',
    mutationType: 'ForecastInput',
  })],
  data () {
    return {
      txType: 'debit',
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-card {
  width: 800px;
}
</style>
