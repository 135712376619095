<template>
  <b-upload
    v-model="file"
    accept=".csv"
  >
    <a class="button is-rounded is-primary is-pulled-right is-outlined">
      <b-icon
        icon="upload"
        size="is-small"
      />
      <span>Upload statement</span>
    </a>
  </b-upload>
</template>

<script>
import gql from 'graphql-tag'
import moment from 'moment'
import { parse } from 'csv-parse/browser/esm/sync'

export default {
  data () {
    return {
      file: null,
    }
  },
  watch: {
    async file () {
      if (!this.file) return

      try {
        const data = await this.csvFileRead(this.file)
        if (data.length === 0) throw new Error('No data found')

        await this.$apollo.mutate({
          mutation: gql`mutation ($items: [AccountTxInput!]!) {
            accountTxImport(items: $items)
          }`,
          variables: {
            items: data,
          },
        })
        this.$buefy.toast.open({
          message: `${data.length} transactions imported`,
          type: 'is-success',
        })
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
      this.file = null
    },
  },
  methods: {
    csvFileRead (file) {
      // OCBC
      const accountNumbers = {
        '601233638201': '1',
        '601231368001': '2',
      }
      const WISE_ACCOUNT = '3'
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          try {
            const items = parse(reader.result, {
              columns: true,
              skip_empty_lines: true
            })
            // Wise
            if (items[0].ID !== undefined) {
              resolve(items.map(i => {
                if (i.Status !== 'COMPLETED') return null
                const data = {
                  date: moment(i['Finished on']).format('YYYY-MM-DD'),
                  accountId: WISE_ACCOUNT,
                  debit: 0,
                  credit: 0,
                  description: i['Reference'],
                  externalId: i['ID'],
                }
                if (i.Direction === 'OUT') {
                  if (i['Source currency'] !== 'USD') return null
                  data.debit = Number(i['Source amount (after fees)']) + Number(i['Source fee amount'])
                  data.details = i['Target name']
                }
                if (i.Direction === 'IN') {
                  if (i['Target currency'] !== 'USD') return null
                  data.credit = Number(i['Target amount (after fees)']) + Number(i['Target fee amount'])
                  data.details = i['Source name']
                }
                return data
              }).filter(i => !!i))
              return
            }
            // OCBC
            if (items.find(i => !i['Account No.'] || accountNumbers[i['Account No.']] === undefined)) reject(new Error('Wrong account number found!'))
            resolve(items.map(i => ({
              date: moment(i['Statement Value Date'], 'YYYYMMDD').format('YYYY-MM-DD'),
              description: i['Ref For Account Owner'].substr(0, 255),
              details: i['Statement Details Info'].substr(0, 255),
              debit: Number(i['Debit Amount']),
              credit: Number(i['Credit Amount']),
              balance: Number(i['Closing Book Balance']),
              accountId: accountNumbers[i['Account No.']],
            })))
          } catch (e) {
            console.error(e)
            reject(new Error('Could not parse the CSV file'))
          }
        }
        reader.onerror = () => {
          reject(new Error('File read error'))
        }
        reader.readAsText(file)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
