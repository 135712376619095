<template>
  <form
    v-if="localValue"
    class="modal-card"
    @submit.prevent="saveChanges"
  >
    <div class="modal-card-body">
      <div class="columns">
        <div class="column">
          <b-field label="Name">
            <b-input
              ref="autofocus"
              v-model="localValue.name"
            />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Personal Name">
            <b-input
              v-model="localValue.personalName"
            />
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Email">
            <b-input v-model="localValue.email" />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Phone">
            <b-input v-model="localValue.phone" />
          </b-field>
        </div>
      </div>
      <b-field label="Address">
        <b-input
          v-model="localValue.address"
          type="textarea"
          rows="2"
        />
      </b-field>
      <div class="columns">
        <div class="column">
          <b-field label="City">
            <b-input v-model="localValue.city" />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Postcode">
            <b-input v-model="localValue.postcode" />
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Country">
            <b-input v-model="localValue.country" />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Province/State">
            <b-input v-model="localValue.state" />
          </b-field>
        </div>
      </div>
      <b-field label="VAT ID">
        <b-input v-model="localValue.vatId" />
      </b-field>
      <b-field>
        <b-switch
          v-model="localValue.hasShippingDetails"
          size="is-small"
        >
          Shipping address is different
        </b-switch>
      </b-field>
      <template v-if="localValue.hasShippingDetails">
        <h3 class="is-size-4 mb-3">Shipping</h3>
        <div class="columns">
          <div class="column">
            <b-field label="Email">
              <b-input v-model="localValue.shippingEmail" />
            </b-field>
          </div>
          <div class="column">
            <b-field label="Phone">
              <b-input v-model="localValue.shippingPhone" />
            </b-field>
          </div>
        </div>
        <b-field label="Address">
          <b-input
            v-model="localValue.shippingAddress"
            type="textarea"
            rows="2"
          />
        </b-field>
        <div class="columns">
          <div class="column">
            <b-field label="City">
              <b-input v-model="localValue.shippingCity" />
            </b-field>
          </div>
          <div class="column">
            <b-field label="Postcode">
              <b-input v-model="localValue.shippingPostcode" />
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Country">
              <b-input v-model="localValue.shippingCountry" />
            </b-field>
          </div>
          <div class="column">
            <b-field label="Province/State">
              <b-input v-model="localValue.shippingState" />
            </b-field>
          </div>
        </div>
      </template>
    </div>
    <footer
      class="modal-card-foot"
      style="justify-content: space-between"
    >
      <b-button
        :loading="isLoading"
        label="Save changes"
        type="is-primary"
        native-type="submit"
      />
      <b-button
        label="Cancel"
        outlined
        @click="$parent.close()"
      />
    </footer>
  </form>
</template>

<script>
import ModalMixin from './_mixin'

export default {
  mixins: [ModalMixin({
    defaultValue: {
      name: '',
      personalName: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      postcode: '',
      country: '',
      state: '',
      vatId: '',
      hasShippingDetails: false,
      shippingEmail: '',
      shippingPhone: '',
      shippingAddress: '',
      shippingCity: '',
      shippingPostcode: '',
      shippingCountry: '',
      shippingState: '',
    },
    mutationName: 'customerCreateOrUpdate',
    mutationType: 'CustomerInput',
  })],
}
</script>

<style lang="scss" scoped>
</style>
