import Vue from 'vue'
import Vuex from 'vuex'
import gql from 'graphql-tag'
import store from 'store'
import apollo from './apollo'
import moment from 'moment'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    customers: [],
    designers: [],
    products: [],
    productFamilies: [],
    orders: [],
    accounts: [],
    token: store.get('erpToken', null),
  },
  getters: {
    customersById: state => {
      const res = {}
      state.customers.forEach((item) => {
        res[item.id] = item
      })
      return res
    },
    designersById: state => {
      const res = {}
      state.designers.forEach((item) => {
        res[item.id] = item
      })
      return res
    },
    designerFeesById: state => {
      const res = {}
      state.designers.forEach((item) => {
        item.fees.forEach((fee) => {
          res[fee.id] = {
            ...fee,
            designerId: item.id,
            fullLabel: `${item.name} - ${fee.label}`,
          }
        })
      })
      return res
    },
    productsById: state => {
      const res = {}
      state.products.forEach((item) => {
        res[item.id] = item
      })
      return res
    },
    productFamiliesById: state => {
      const res = {}
      state.productFamilies.forEach((item) => {
        res[item.id] = item
      })
      return res
    },
    productDesignFees: (_state, getters) => ({ productId, date, designerId }) => {
      date = date || moment().format('YYYY-MM-DD')
      const product = getters.productsById[productId]
      if (!product) return []
      let fees = product.designFees.map(item => {
        const fee = getters.designerFeesById[item.designerFeeId]
        if (designerId && fee.designerId !== designerId) return null
        if (!fee) return null
        if (fee.startDate && fee.startDate > date) return null
        if (fee.endDate && fee.endDate < date) return null
        return {
          designerId: fee.designerId,
          qty: item.qty,
          amount: fee.amount,
          label: fee.label,
        }
      })
      return fees.filter(i => !!i)
    },
    productDesignFeesTotal: (state, getters) => {
      const res = {}
      state.products.forEach((item) => {
        const designFeesTotal = getters.productDesignFees({
          productId: item.id,
        }).reduce((total, fee) => {
          return total + fee.amount * fee.qty
        }, 0)
        res[item.id] = designFeesTotal
      })
      return res
    },
    productPrice: state => (productId, qty) => {
      if (!productId) return null
      const product = state.products.find(i => i.id === productId)
      if (!product) return null
      for (let i = 0; i < product.prices.length; i++) {
        if (qty < product.prices[i].moq) break
        if (!product.prices[i + 1])
          return product.prices[i].amount
        if (qty < product.prices[i + 1].moq)
          return product.prices[i].amount
      }
      return null
    },
    productProfit: state => (productId, unitPrice) => {
      if (!productId) return null
      const product = state.products.find(i => i.id === productId)
      if (!product || !product.cost) return null
      return unitPrice - product.cost
    },
    accountsById: state => {
      const res = {}
      state.accounts.forEach((item) => {
        res[item.id] = item
      })
      return res
    },
  },
  mutations: {
    CUSTOMERS (state, payload) {
      state.customers = payload
    },
    DESIGNERS (state, payload) {
      state.designers = payload
    },
    ORDERS (state, payload) {
      state.orders = payload
    },
    PRODUCTS (state, payload) {
      state.products = payload
    },
    PRODUCT_FAMILIES (state, payload) {
      state.productFamilies = payload
    },
    ACCOUNTS (state, payload) {
      state.accounts = payload
    },
    TOKEN (state, payload) {
      state.token = payload
      store.set('erpToken', payload)
    },
    TOKEN_LOGOUT (state) {
      state.token = null
      store.remove('erpToken')
    },
  },
  actions: {
    async getCustomers ({ state, commit }, force = false) {
      if (!force && state.customers.length > 0) return
      const { data } = await apollo.defaultClient.query({
        query: gql`query {
          customers { id name email }
        }`,
        fetchPolicy: 'no-cache',
      })
      commit('CUSTOMERS', data.customers)
    },
    async getDesigners ({ state, commit }, force = false) {
      if (!force && state.designers.length > 0) return
      const { data } = await apollo.defaultClient.query({
        query: gql`query {
          designers {
            id name
            fees { id amount label startDate endDate }
            invoices { id invoiceNumber invoiceDate amount paymentDate }
          }
        }`,
        fetchPolicy: 'no-cache',
      })
      commit('DESIGNERS', data.designers)
    },
    async getOrders ({ state, commit }, force = false) {
      if (!force && state.products.length > 0) return
      const { data } = await apollo.defaultClient.query({
        query: gql`query {
          orders {
            id invoiceDate amount shippingAmount discountAmount invoiceId
            customer { name }
            items { qty unitPrice productId }
          }
        }`,
        fetchPolicy: 'no-cache',
      })
      commit('ORDERS', data.orders)
    },
    async getProducts ({ state, commit }, force = false) {
      if (!force && state.products.length > 0) return
      const { data } = await apollo.defaultClient.query({
        query: gql`query {
          products {
            id name isAvailable msrp cost
            family { id name }
            prices { moq amount }
            designFees { designerFeeId qty }
          }
        }`,
        fetchPolicy: 'no-cache',
      })
      commit('PRODUCTS', data.products)
    },
    async getProductFamilies ({ state, commit }, force = false) {
      if (!force && state.productFamilies.length > 0) return
      const { data } = await apollo.defaultClient.query({
        query: gql`query {
          productFamilies { id name }
        }`,
        fetchPolicy: 'no-cache',
      })
      commit('PRODUCT_FAMILIES', data.productFamilies)
    },
    async getAccounts ({ state, commit }, force = false) {
      if (!force && state.accounts.length > 0) return
      const { data } = await apollo.defaultClient.query({
        query: gql`query {
          accounts { id name }
        }`,
        fetchPolicy: 'no-cache',
      })
      commit('ACCOUNTS', data.accounts)
    },
    async login ({ commit }, variables) {
      const { data } = await apollo.defaultClient.query({
        query: gql`query ($email: String!, $password: String!) {
          userLogin(
            email: $email
            password: $password
          )
        }`,
        variables,
        fetchPolicy: 'no-cache',
      })
      commit('TOKEN', data.userLogin)
    },
  }
})
