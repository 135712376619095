import Vue from 'vue'
import numeral from 'numeral'

const symbols = {
  USD: '$',
  SGD: 'SG$',
}

Vue.filter('$', function (v, decimals = 2, currency = 'USD') {
  if (v === 0) return '-'
  let format = '0,0'
  if (decimals > 0) format += '.'
  for (let i = 0; i < decimals; i++) format += '0'
  return v >= 0
    ? `${symbols[currency]}${numeral(v).format(format)}`
    : `-${symbols[currency]}${numeral(-v).format(format)}`
})
