<template>
  <form
    v-if="localValue"
    class="modal-card"
    @submit.prevent="saveChanges"
  >
    <div class="modal-card-body">
      <b-field label="Name">
        <b-input
          ref="autofocus"
          v-model="localValue.name"
        />
      </b-field>
    </div>
    <footer
      class="modal-card-foot"
      style="justify-content: space-between"
    >
      <b-button
        :loading="isLoading"
        label="Save changes"
        type="is-primary"
        native-type="submit"
      />
      <b-button
        label="Cancel"
        outlined
        @click="$parent.close()"
      />
    </footer>
  </form>
</template>

<script>
import ModalMixin from './_mixin'

export default {
  mixins: [ModalMixin({
    defaultValue: {
      name: '',
    },
    mutationName: 'productFamilyCreateOrUpdate',
    mutationType: 'ProductFamilyInput',
  })],
}
</script>

<style lang="scss" scoped>
</style>
