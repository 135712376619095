<template>
  <div class="field has-addons">
    <p class="control">
      <b-button
        icon-left="angle-left"
        @click="updateValue(-1)"
      />
    </p>
    <p
      class="control"
      style="width: 100px"
    >
      <b-button expanded>
        {{ label }}
      </b-button>
    </p>
    <p class="control">
      <b-button
        icon-left="angle-right"
        @click="updateValue(1)"
      />
    </p>
  </div>
</template>

<script>
import moment from 'moment'

const INPUT_FORMAT = 'YYYY'
const DISPLAY_FORMAT = 'YYYY'

export default {
  props: {
    value: {
      type: String,
      default: () => moment().day(1).format(INPUT_FORMAT),
    },
  },
  computed: {
    label () {
      return moment(this.value, INPUT_FORMAT).format(DISPLAY_FORMAT)
    },
  },
  methods: {
    updateValue (offset) {
      const value = moment(this.value, INPUT_FORMAT).add(offset, 'year').format(INPUT_FORMAT)
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
