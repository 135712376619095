export default {
  props: {
    value: {
      type: String,
      default: () => null,
    },
    autofocus: {
      type: Boolean,
      default: () => false,
    },
    size: {
      type: String,
      default: () => undefined,
    },
  },
  data () {
    return {
      name: '',
    }
  },
  watch: {
    item () {
      this.focus()
    },
  },
  mounted () {
    if (this.autofocus) this.focus()
  },
  methods: {
    focus () {
      this.$nextTick(() => {
        if (this.$refs.autofocus) this.$refs.autofocus.focus()
      })
    },
    displayLabel (v) {
      return v.name
    },
    setValue (v) {
      this.$emit('input', v.id)
    },
  },
}
