<template>
  <div class="card p-4">
    <h2 class="is-size-3 mb-4">
      Fake Invoice
    </h2>
    <div class="columns">
      <div class="column">
        <b-field label="Date">
          <b-datepicker
            v-model="invoice.date"
            icon="calendar"
          />
        </b-field>
        <b-field label="ID">
          <b-input
            :value="invoiceData.id"
            disabled
          />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Shipping Address">
          <b-input
            v-model="invoice.shippingAddress"
            type="textarea"
          />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Billing Address" />
        <b-input
          v-if="!sameAddress"
          v-model="invoice.shippingAddress"
          type="textarea"
          class="mb-3"
        />
        <b-switch v-model="sameAddress">
          Same as shipping
        </b-switch>
      </div>
    </div>
    <table class="table is-fullwidth">
      <thead>
        <th>Item</th>
        <th width="200">
          Qty
        </th>
        <th width="100">
          Unit price
        </th>
        <th width="120">
          Price
        </th>
        <th width="10" />
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in invoice.items"
          :key="i"
        >
          <td>
            <b-input v-model="invoice.items[i].name" />
            <b-input
              v-model="invoice.items[i].description"
              size="is-small"
              type="textarea"
              class="mt-3"
            />
          </td>
          <td>
            <b-numberinput v-model="invoice.items[i].qty" />
          </td>
          <td>
            <b-input v-model="invoice.items[i].unitPrice" />
          </td>
          <td>{{ item.qty * item.unitPrice | $ }}</td>
          <td>
            <b-button
              icon-left="trash"
              type="is-danger"
              size="is-small"
              outlined
              rounded
              @click="invoice.items.splice(i, 1)"
            />
          </td>
        </tr>
      </tbody>
      <tfoot>
        <th
          class="has-text-align-right"
          colspan="3"
        >
          Total:
        </th>
        <th>{{ invoiceData.amount | $ }}</th>
      </tfoot>
    </table>
    <b-button
      size="is-small"
      icon-left="sync"
      type="is-danger"
      class="is-pulled-right"
      outlined
      @click="invoice.items = []"
    >
      Clear items
    </b-button>
    <b-button
      size="is-small"
      icon-left="plus"
      @click="addItem"
    >
      Add item
    </b-button>
    <div class="buttons mt-6">
      <b-button
        type="is-primary"
        size="is-large"
        @click="downloadPdf"
      >
        Download PDF
      </b-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import store from 'store'
import moment from 'moment'
import gql from 'graphql-tag'

const defaultInvoice = () => ({
  id: null,
  date: moment().format('YYYY-MM-DD'),
  billingAddress: '',
  shippingAddress: '',
  amount: '',
  items: [],
})

export default {
  props: {
    prefill: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    const invoice = defaultInvoice()
    if (Object.keys(this.prefill).length > 0)
      _.assign(invoice, this.prefill)
    invoice.date = moment(invoice.date).toDate()

    return {
      invoice,
      sameAddress: !invoice.shippingAddress || invoice.shippingAddress === invoice.billingAddress,
    }
  },
  computed: {
    invoiceData () {
      return {
        id: this.invoice.id || `${moment(this.invoice.date).format('YYMMDD')}003`,
        date: moment(this.invoice.date).format('YYYY-MM-DD'),
        shippingAddress: this.invoice.shippingAddress,
        billingAddress: this.sameAddress ? this.invoice.shippingAddress : this.invoice.billingAddress,
        items: JSON.stringify(this.invoice.items),
        amount: this.invoice.items.reduce((sum, i) => sum + i.qty * i.unitPrice, 0),
      }
    },
  },
  methods: {
    addItem () {
      this.invoice.items.push({
        name: '',
        description: '',
        qty: 1,
        unitPrice: '',
      })
    },
    async downloadPdf () {
      try {
        const { data } = await this.$apollo.query({
          query: gql`query ($item: InvoiceFakeInput!) {
            invoiceFake(item: $item)
          }`,
          variables: {
            item: this.invoiceData,
          },
          fetchPolicy: 'no-cache',
        })

        const fileUrl = `data:application/pdf;base64,${data.invoiceFake}`
        const fileData = await fetch(fileUrl)
        const blob = await fileData.blob()
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', `FK-invoice-${this.invoiceData.id}.pdf`)
        link.click()
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }

      const storedItems = store.get('fakeinvoices', [])
      storedItems.unshift(this.invoiceData)
      if (storedItems.length > 8)
        storedItems.splice(8)
      store.set('fakeinvoices', storedItems)
      this.$parent.close()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
