<template>
  <div
    v-if="$store.state.designers.length > 0 && $store.state.products.length > 0"
  >
    <div
      class="balance"
      style="float:right"
    >
      Overall total: {{ total | $ }}
    </div>
    <div class="balance">
      Current balance: {{ balance | $ }}
    </div>

    <h1 class="is-size-3 mb-2 mt-4">
      History
    </h1>
    <table class="table">
      <thead>
        <th>Date</th>
        <th>Details</th>
        <th>Amount</th>
      </thead>
      <tbody>
        <tr
          v-for="(d, i) in details"
          :key="'details' + i"
          :class="{ pending: isLater(d.date) }"
        >
          <td>{{ d.date }}</td>
          <td>{{ d.label }}</td>
          <td>
            <template v-if="d.debit">
              -{{ d.debit | $ }}
            </template>
            <template v-if="d.credit">
              +{{ d.credit | $ }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>

    <h1 class="is-size-3 mb-2">
      Details
    </h1>
    <div
      v-for="(data, period) in reportData"
      :key="period"
      :class="{ pending: isLater(period, true) }"
    >
      <h2 class="is-size-4">
        {{ period }}
      </h2>
      <b-table
        :data="Object.values(data.items)"
        class="mb-5 mt-2"
      >
        <b-table-column
          v-slot="props"
          label="Qty"
          width="100"
          numeric
        >
          {{ props.row.qty }}
        </b-table-column>
        <b-table-column
          v-slot="props"
        >
          {{ props.row.label }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Unit Fee"
          numeric
        >
          <div class="unit-fee">
            {{ props.row.fee | $(4) }}
          </div>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Amount"
          width="150"
          numeric
        >
          {{ props.row.total | $ }}
        </b-table-column>
        <template #footer>
          <div class="has-text-right">
            Total: {{ feesTotal(data.items) | $ }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import moment from 'moment'

export default {
  props: {
    filters: {
      type: Object,
      default: () => null,
    },
  },
  apollo: {
    orders: gql`query {
      orders {
        invoiceDate
        items { productId qty unitPrice }
      }
    }`,
  },
  computed: {
    reportData () {
      const data = {}
      if (!this.orders || !this.filters || !this.filters.designerId) return data
      this.orders.forEach(order => {
        const datePeriod = moment(order.invoiceDate).format('YYYY [Q]Q')
        if (!datePeriod) return

        order.items.forEach(item => {
          if (!order.invoiceDate) return

          const fees = this.$store.getters.productDesignFees({
            productId: item.productId,
            date: order.invoiceDate,
            designerId: this.filters.designerId,
          })

          fees.forEach(fee => {
            if (data[datePeriod] === undefined) data[datePeriod] = {
              amount: 0,
              items: {},
            }

            const qty = Number(item.qty) || 0
            const label = fee.label

            data[datePeriod].amount += qty * fee.qty * fee.amount

            if (data[datePeriod].items[label] === undefined)
              data[datePeriod].items[label] = {
                label,
                fee: fee.amount,
                qty: 0,
                total: 0,
              }
            data[datePeriod].items[label].qty += qty * fee.qty
            data[datePeriod].items[label].total += qty * fee.qty * fee.amount
          })
        })
      })
      return data
    },
    details () {
      const details = []
      for (const period in this.reportData) {
        const m = moment(period, 'YYYY [Q]Q').add(3, 'months')
        details.push({
          date: m.format('YYYY-MM-DD'),
          label: `Fees ${period}`,
          debit: 0,
          credit: this.reportData[period].amount,
        })
      }
      if (!this.filters || !this.filters.designerId) return details
      const designer = this.$store.getters.designersById[this.filters.designerId]
      designer.invoices.forEach(item => {
        let label = `Invoice ${item.invoiceNumber}`
        if (item.paymentDate)
          label += ` (paid ${item.paymentDate})`
        details.push({
          date: item.invoiceDate,
          label,
          debit: item.amount,
          credit: 0,
        })
      })
      return details.sort((a, b) => a.date < b.date ? 1 : -1)
    },
    total () {
      return this.details.reduce((balance, item) => balance + item.credit, 0)
    },
    balance () {
      return this.details.reduce((balance, item) => {
        if (this.isLater(item.date)) return balance
        return balance + item.credit - item.debit
      }, 0)
    },
  },
  created () {
    this.$store.dispatch('getDesigners')
    this.$store.dispatch('getProducts')
  },
  methods: {
    feesTotal (items) {
      return Object.values(items).reduce((total, item) => total + item.total, 0)
    },
    isLater (date, isQuarter = false) {
      if (!isQuarter)
        return moment().isBefore(moment(date))

      return moment().subtract(3, 'months').isBefore(moment(date, 'YYYY [Q]Q'))
    },
  },
}
</script>

<style lang="scss" scoped>
.unit-fee {
  opacity: .4;
}
.balance {
  font-weight: bold;
  font-size: 1.1em;
}
.pending {
  opacity: .4;
}
</style>
