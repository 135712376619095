<template>
  <div
    v-if="showReport"
    class="container"
  >
    <!-- <b-field>
      <b-radio-button
        v-model="showReport"
        v-for="report in reports"
        :key="report.key"
        :native-value="report.key"
        size="is-small"
      >
        {{ report.label }}
      </b-radio-button>
    </b-field> -->
    <h1 class="is-size-3">
      {{ report.label }}
    </h1>
    <component
      :is="'Filter' + report.key"
      v-if="filters"
      v-model="filters"
      class="mt-3 mb-5"
    />
    <component
      :is="'Report' + report.key"
      :filters="filters"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import store from 'store'

import ReportDesignFees from '@/components/Reports/DesignFees.vue'
import ReportDesignFeesFilter from '@/components/Reports/DesignFeesFilter.vue'
import ReportPnl from '@/components/Reports/Pnl.vue'
import ReportPnlFilter from '@/components/Reports/PnlFilter.vue'
import ReportSalesByPeriod from '@/components/Reports/SalesByPeriod.vue'
import ReportSalesByPeriodFilter from '@/components/Reports/SalesByPeriodFilter.vue'

const reports = [
  {
    key: 'SalesByPeriod',
    component: ReportSalesByPeriod,
    label: 'Sales',
    filterComponent: ReportSalesByPeriodFilter,
    filterDefault: () => ({
      groupBy: 'month',
      customerId: null,
      productId: null,
      productFamilyId: null,
      showProfit: true,
      showSales: true,
      showCost: true,
      showDesign: true,
    }),
  },
  {
    key: 'Pnl',
    component: ReportPnl,
    label: 'Profit & Loss',
    filterComponent: ReportPnlFilter,
    filterDefault: () => ({
      groupBy: null,
      startDate: null,
      endDate: null,
    }),
  },
  {
    key: 'DesignFees',
    component: ReportDesignFees,
    label: 'Design Fees',
    filterComponent: ReportDesignFeesFilter,
    filterDefault: () => ({
      designerId: null,
    }),
  },
]

// prepare components to be loaded
const loadComponents = {}
reports.forEach(report => {
  loadComponents['Report' + report.key] = report.component
  if (report.filterComponent)
    loadComponents['Filter' + report.key] = report.filterComponent
})

export default {
  components: {
    ...loadComponents,
  },
  data () {
    return {
      showReport: null,
      reports,
      filters: null,
    }
  },
  computed: {
    report () {
      return this.reports.find(i => i.key === this.showReport)
    },
  },
  watch: {
    report: {
      deep: true,
      handler () {
        this.$router.replace({ path: `/reports/${this.report.key}` })
        if (this.report.filterComponent) {
          const filters = store.get('ReportFilter.' + this.report.key, this.report.filterDefault())
          this.filters = filters
        } else
          this.filters = null
      },
    },
    filters: {
      deep: true,
      handler () {
        if (this.filters) {
          store.set('ReportFilter.' + this.report.key, this.filters)
          this.$router.replace({ query: this.filters })
        } else
          this.$router.replace({ query: undefined })
      },
    },
  },
  created () {
    this.showReport = this.$route.params.key || reports[0].key
  },
}
</script>

<style lang="scss" scoped>
</style>
