import Vue from 'vue'
import VueRouter from 'vue-router'

import Accounting from '@/views/accounting.vue'
import Customers from '@/views/customers.vue'
import Designers from '@/views/designers.vue'
import Forecast from '@/views/forecast.vue'
import Invoices from '@/views/invoices.vue'
import Login from '@/views/login.vue'
import Orders from '@/views/orders.vue'
import Products from '@/views/products.vue'
import Reports from '@/views/reports.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/orders' },
  { path: '/login', component: Login },
  { path: '/customers', component: Customers },
  { path: '/designers', component: Designers },
  { path: '/invoices', component: Invoices },
  { path: '/orders', component: Orders },
  { path: '/products', component: Products },
  { path: '/reports', component: Reports },
  { path: '/reports/:key', component: Reports },
  { path: '/accounting', component: Accounting },
  { path: '/accounting/:tab/:month', component: Accounting },
  { path: '/forecast', component: Forecast },
]

export default new VueRouter({
  mode: 'history',
  routes,
})
