<template>
  <form
    v-if="localValue"
    class="modal-card"
    @submit.prevent="saveChanges"
  >
    <div class="modal-card-body">
      <div class="columns">
        <div class="column">
          <AutocompleteCustomer v-model="localValue.customerId" />
          <b-message
            v-if="missingCustomerEmail"
            size="is-small"
            type="is-danger"
            class="mt-3"
          >
            This customer does not have an email! Add it so he can get an invoice.
          </b-message>
        </div>
        <div class="column is-one-third">
          <b-datepicker
            v-model="localValue.invoiceDate"
            icon="calendar"
          />
        </div>
      </div>

      <h2 class="is-size-3 mb-2">
        Items
      </h2>
      <div
        v-for="(item, i) in localValue.items"
        :key="'item' + i"
        class="columns"
      >
        <div class="column is-narrow">
          <b-button
            icon-left="trash"
            type="is-danger"
            size="is-small"
            outlined
            rounded
            @click="localValue.items.splice(i, 1)"
          />
        </div>
        <div class="column">
          <AutocompleteProduct
            v-model="item.productId"
            autofocus
          />
        </div>
        <div class="column is-narrow">
          <b-input
            v-model="item.qty"
            icon="layer-group"
            style="width: 120px"
          />
        </div>
        <div class="column is-narrow">
          <b-field
            :type="suggestedPrice(item) ? 'is-warning' : null"
            style="margin-bottom: 0"
          >
            <b-input
              v-model="item.unitPrice"
              icon="dollar-sign"
              style="width: 120px"
            />
          </b-field>
          <a
            v-if="suggestedPrice(item)"
            class="is-size-7"
            @click="item.unitPrice = suggestedPrice(item)"
          >
            Suggested: {{ suggestedPrice(item) | $ }}
          </a>
        </div>
        <div class="column is-narrow pt-4 has-text-right">
          <div style="width: 100px">
            {{ itemTotal(item) | $ }}
          </div>
        </div>
      </div>
      <strong
        v-if="itemsTotal"
        class="is-pulled-right"
      >
        Total: {{ itemsTotal | $ }}
      </strong>
      <b-button
        icon-left="plus"
        size="is-small"
        rounded
        @click="addOrderItem"
      >
        Add item
      </b-button>

      <h2 class="is-size-3 mb-2 mt-4">
        Other
      </h2>
      <div class="columns">
        <div class="column">
          <b-field label="Shipping">
            <b-input
              v-model="localValue.shippingAmount"
              icon="dollar-sign"
            />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Discount">
            <b-input
              v-model="localValue.discountAmount"
              icon="dollar-sign"
            />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Total">
            <b-input
              v-model="localValue.amount"
              icon="dollar-sign"
              disabled
            />
          </b-field>
        </div>
      </div>
      <b-field
        v-if="localValue.discountAmount"
        label="Discount Reason"
      >
        <b-input v-model="localValue.discountLabel" />
      </b-field>
    </div>
    <footer
      class="modal-card-foot"
      style="justify-content: space-between"
    >
      <b-button
        :loading="isLoading"
        label="Save changes"
        type="is-primary"
        native-type="submit"
      />
      <b-button
        label="Cancel"
        outlined
        @click="$parent.close()"
      />
    </footer>
  </form>
</template>

<script>
import moment from 'moment'

import ModalMixin from './_mixin'

import AutocompleteCustomer from '@/components/Autocomplete/Customer.vue'
import AutocompleteProduct from '@/components/Autocomplete/Product.vue'

export default {
  components: {
    AutocompleteCustomer,
    AutocompleteProduct,
  },
  mixins: [ModalMixin({
    defaultValue: {
      customerId: '',
      invoiceDate: null,
      amount: '',
      shippingAmount: '',
      discountAmount: '',
      discountLabel: '',
      items: [],
    },
    beforeSetValue: v => ({
      ...v,
      invoiceId: undefined,
      invoiceDate: v.invoiceDate
        ? moment(v.invoiceDate, 'YYYY-MM-DD').toDate()
        : moment().toDate(),
    }),
    beforeSaveValue: v => ({
      ...v,
      invoiceDate: v.invoiceDate
        ? moment(v.invoiceDate).format('YYYY-MM-DD')
        : null,
      amount: Number(v.amount) || null,
      shippingAmount: Number(v.shippingAmount) || null,
      discountAmount: Number(v.discountAmount) || null,
      items: v.items.map(i => ({
        ...i,
        qty: Number(i.qty) || null,
        unitPrice: Number(i.unitPrice) || null,
        __typename: undefined,
      })),
      accountTxes: undefined,
    }),
    mutationName: 'orderCreateOrUpdate',
    mutationType: 'OrderInput',
  })],
  computed: {
    itemsTotal () {
      return this.localValue.items.reduce((total, item) => {
        const amount = this.itemTotal(item) || 0
        return total + amount
      }, 0)
    },
    missingCustomerEmail () {
      if (!this.localValue.customerId) return false
      const customer = this.$store.state.customers.find(i => i.id === this.localValue.customerId)
      if (!customer) return false
      return !customer.email
    },
  },
  watch: {
    itemsTotal () {
      this.updateAmount()
    },
    'localValue.shippingAmount' () {
      this.updateAmount()
    },
    'localValue.discountAmount' () {
      this.updateAmount()
    },
  },
  methods: {
    addOrderItem () {
      this.localValue.items.push({
        id: null,
        productId: null,
        qty: '',
        unitPrice: '',
      })
    },
    suggestedPrice (item) {
      const qty = Number(item.qty) || 0
      const unitPrice = Number(item.unitPrice) || null
      const price = this.$store.getters.productPrice(item.productId, qty)
      if (price && price !== unitPrice) return price
    },
    itemTotal (item) {
      const qty = Number(item.qty)
      const unitPrice = Number(item.unitPrice)
      return isNaN(qty) || isNaN(unitPrice)
        ? ''
        : qty * unitPrice
    },
    updateAmount () {
      this.localValue.amount = this.itemsTotal
        + Number(this.localValue.shippingAmount)
        - Number(this.localValue.discountAmount)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-card {
  width: 900px;
}
</style>
