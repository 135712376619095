<template>
  <div>
    <b-field>
      <b-radio-button
        v-for="item in $store.state.designers"
        :key="item.id"
        v-model="localValue.designerId"
        :native-value="item.id"
        size="is-small"
      >
        {{ item.name }}
      </b-radio-button>
    </b-field>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      localValue: JSON.parse(JSON.stringify(this.value)),
    }
  },
  watch: {
    localValue: {
      deep: true,
      handler () {
        this.$emit('input', JSON.parse(JSON.stringify(this.localValue)))
      },
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
